import { Injectable, Injector } from "@angular/core";
import { Router } from '../../../../node_modules/@angular/router';
import { Subject } from '../../../../node_modules/rxjs';

@Injectable()
export class PageController {

  static Instance: PageController;

  private _subject = new Subject<any>();

  constructor(private router: Router) { PageController.Instance = this; }

  newEvent(event){
    this._subject.next(event);
  }

  get events$(){
    return this._subject.asObservable();
  }

  Close() { // closes whichever page the user is on.
    this.router.navigate(['']);
  }


  public ResetPageControllers() {
    console.log('RESET');
    this.newEvent('Reset');
  }


}
