import { Injectable } from '@angular/core';
import { PlatformLocation } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';


/**
 * Declaration of config class
 */
export class ApplicationConfig
{
// Your properties here
  readonly production: boolean;
  readonly name: string;
  readonly url: string;
  readonly reportViewerUrl: string;
  readonly version: string;
  readonly showInactiveSessionAt: number; // 600; // when we reach this number, we will display inactive session dialog.
  readonly min_MaxSecondsInactive: number; // the minimum number the user can set the max seconds inacitve setting to.
  readonly appTitle: string;
  readonly reportTitle: string;

}

/**
 * Global variable containing actual config to use. Initialised via ajax call
 */
export let APP_CONFIG: ApplicationConfig;

/**
 * Service in charge of dynamically initialising configuration
 */
@Injectable()
export class ApplicationConfigService
{

  constructor(private http: HttpClient, private platformLocation: PlatformLocation) 
  {
  }

  public load()
  {
    return new Promise((resolve, reject) => {
      let baseHref = '/';
      const pfl = this.platformLocation;
      if(pfl) { baseHref = this.platformLocation.getBaseHrefFromDOM(); }
      const confName = environment.name + '.json';
      this.http.get(baseHref + 'assets/config/' + confName).pipe(map((res) => res as any), catchError(error => {
          console.log('Appconfig failed: ', error);
          reject(true);
          return Observable.throw('Unable to fetch configuration file.');
        })).subscribe((envResponse: any) => {
        const t = new ApplicationConfig();
        // Modify envResponse here if needed (e.g. to ajust parameters for https,...)
        APP_CONFIG = Object.assign(t, envResponse);
        resolve(true);        
      });
    });
  }
}

