import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { APP_CONFIG } from './ApplicationConfig.service';
import { Subject } from 'rxjs';
import { PlatformLocation } from '@angular/common';
import { SharedService } from './shared.service';
import { TransactionInfo } from '../models/TransactionInfo';
import { District } from '../models/District';
import { Contact } from '../models/Contact';
import { Building } from '../models/Building';
import { AccountInfo } from '../models/AccountInfo';
import { Installment } from '../models/Installment';
import { ServiceClass } from '../models/ServiceClass';
import { LoadArea } from '../models/LoadArea';
import { GenerateReportDTO, ReportParam } from '../models/Report';
import { YearAccess } from '../models/YearAccess';
import { CustomPropertyValue } from '../models/CustomProperty';


@Injectable({
  providedIn: "root"
})
export class DataService {
  static baseUrl = '';
  public url = APP_CONFIG.url;
  public toastSuccess = new Subject;
  public toastOneTimeSuccess = new Subject;
  public toastError = new Subject;
  public toastInfo = new Subject;
  public toastWarning = new Subject;
  public toastOneTimeWarning = new Subject;
  public toastClear = new Subject;

  public isDirty = false;


  constructor(private http: HttpClient, private pfl: PlatformLocation, public sharedService: SharedService) {
    DataService.baseUrl = this.pfl.getBaseHrefFromDOM();
  }
  
  public GetSuppliers() {
    console.log('getting suppliers...');
    let url = this.url + 'api/providers/getsuppliers';   
    // this.sharedService.CauseLoading();
    const pr = new Promise((resolve, reject) => {
      this.http.get(url).toPromise()
        .then(
          res => {
            // this.sharedService.StopLoading();
            resolve(res);
          }, rej => {
            // this.sharedService.StopLoading();
            reject(rej);
          }
        );
    });
    return pr;
  }

  public GetSuppliersForYear(ActiveYear?: number) {
    console.log('getting suppliers...');
    let url = this.url + 'api/providers/getsuppliers';
    if(ActiveYear>0){url = url + '?year=' + ActiveYear;    }
    // this.sharedService.CauseLoading();
    const pr = new Promise((resolve, reject) => {
      this.http.get(url).toPromise()
        .then(
          res => {
            // this.sharedService.StopLoading();
            resolve(res);
          }, rej => {
            // this.sharedService.StopLoading();
            reject(rej);
          }
        );
    });
    return pr;
  }

  public GetAvailableSuppliersByAccountId(acct) {
    console.log('getting suppliers for account...');
    let url = this.url + 'api/providers/GetAvailableSuppliersByAccountId';
    url = url + '?acct=' + acct;
    // this.sharedService.CauseLoading();
    const pr = new Promise((resolve, reject) => {
      this.http.get(url).toPromise()
        .then(
          res => {
            // this.sharedService.StopLoading();
            resolve(res);
          }, rej => {
            // this.sharedService.StopLoading();
            reject(rej);
          }
        );
    });
    return pr;
  }

  public GetUtilities() {
    console.log('getting Utilities...');
    const url = this.url + 'api/providers/getutilities';
    // this.sharedService.CauseLoading();
    const pr = new Promise((resolve, reject) => {
      this.http.get(url).toPromise()
        .then(
          res => {
            // this.sharedService.StopLoading();
            resolve(res);
          }, rej => {
            // this.sharedService.StopLoading();
            reject(rej);
          }
        );
    });
    return pr;
  }

  public SaveSupplier(dto) {
    console.log('saving supplier...');
    const url = this.url + 'api/providers/savesupplier';
    // this.sharedService.CauseLoading();
    const pr = new Promise((resolve, reject) => {
      this.http.post(url, dto).toPromise()
        .then(
          res => {
            // this.sharedService.StopLoading();
            this.ToastSuccess('Supplier Successfully Saved!');
            resolve(res);
          }, rej => {
            // this.sharedService.StopLoading();
            this.ToastError('Save failed. Please try again.');
            reject(rej);
          }
        );
    });
    return pr;
  }

  public SaveUtility(dto) {
    console.log('saving Utility...');
    const url = this.url + 'api/providers/saveutility';
    // this.sharedService.CauseLoading();
    const pr = new Promise((resolve, reject) => {
      this.http.post(url, dto).toPromise()
        .then(
          res => {
            // this.sharedService.StopLoading();
            this.ToastSuccess('Utility Successfully Saved!');
            resolve(res);
          }, rej => {
            // this.sharedService.StopLoading();
            this.ToastError('Save failed. Please try again.');
            reject(rej);
          }
        );
    });
    return pr;
  }

  public GetTransactionList(type) {
    console.log('getting Account Id List...');
    const url = this.url + 'api/Transaction/GetTransactionList?type='+type;
    const pr = new Promise((resolve, reject) => {
      this.http.get(url).toPromise()
        .then(
          res => {
            resolve(res);
          }, rej => {
            reject(rej);
          }
        );
    });
    return pr;
  }

  public GetTransactionsWithNotes(yr) {
    console.log('getting transactions with notes...');
    const url = this.url + 'api/Transaction/GetTransactionsWithNotes?yearid='+yr;
    const pr = new Promise((resolve, reject) => {
      this.http.get(url).toPromise()
        .then(
          res => {
            resolve(res);
          }, rej => {
            reject(rej);
          }
        );
    });
    return pr;
  }

  public UpdateTransactionNote(id, note, internal) {
    console.log('updating transaction note...');
    const dto = {transactionid: id, note: note, isinternal: internal};
    const url = this.url + 'api/Transaction/UpdateTransactionNote';
    const pr = new Promise((resolve, reject) => {
      this.http.post(url, dto).toPromise()
        .then(
          res => {
            resolve(res);
          }, rej => {
            reject(rej);
          }
        );
    });
    return pr;
  }

  public GetAllUnpostedTransactions() {
    console.log('getting unposted transactions...');
    const url = this.url + 'api/Transaction/GetAllUnpostedTransactions';
    const pr = new Promise((resolve, reject) => {
      this.http.get(url).toPromise()
        .then(
          res => {
            resolve(res);
          }, rej => {
            reject(rej);
          }
        );
    });
    return pr;
  }

  public GetTransactionInfo(uid) {
    console.log('getting transaction info...');
    const url = this.url + 'api/Transaction/GetTransactionInfo?uid='+uid;
    // this.sharedService.CauseLoading();
    const pr = new Promise((resolve, reject) => {
      this.http.get(url).toPromise()
        .then(
          res => {
            // this.sharedService.StopLoading();
            resolve(res);
          }, rej => {
            // this.sharedService.StopLoading();
            reject(rej);
          }
        );
    });
    return pr;
  }

  public SaveTransaction(trans: TransactionInfo) {
    console.log('saving transaction info...');
    const url = this.url + 'api/Transaction/SaveTransaction';
    const pr = new Promise((resolve, reject) => {
      this.http.post(url, trans).toPromise()
        .then(
          res => {
            // this.sharedService.StopLoading();
            resolve(res);
          }, rej => {
            // this.sharedService.StopLoading();
            reject(rej);
          }
        );
    });
    return pr;
  }

  public CheckAccountNumber(accountNumber) {
    console.log('Checking if account number exists...');
    const url = this.url + 'api/Accounts/CheckAccountNumber?acct='+encodeURI(accountNumber);
    const pr = new Promise((resolve, reject) => {
      this.http.get(url).toPromise()
        .then(
          res => {
            resolve(res);
          }, rej => {
            reject(rej);
          }
        );
    });
    return pr;
  }

  public IsAccountActive(accountNumber) {
    console.log('Checking if account is active...');
    const url = this.url + 'api/Accounts/IsActive?acct='+encodeURI(accountNumber);
    const pr = new Promise((resolve, reject) => {
      this.http.get(url).toPromise().then(
          res => {
            resolve(res);
          }, rej => {
            reject(rej);
          }
        );
    });
    return pr;
  }

  public GetAccountInfo(accountNumber) {
    console.log('Getting Account Info...');
    const url = this.url + 'api/Accounts/GetAccountInfo?acct='+accountNumber;
    const pr = new Promise((resolve, reject) => {
      this.http.get(url).toPromise()
        .then(
          res => {
            resolve(res);
          }, rej => {
            reject(rej);
          }
        );
    });
    return pr;
  }

  public GetAccountByBuildingId(buildingId) {
    console.log('Getting Account Info By Building Id...');
    const url = this.url + 'api/Accounts/GetAccountByBuildingId?buildingId=' + buildingId;
    // this.sharedService.CauseLoading();
    const pr = new Promise((resolve, reject) => {
      this.http.get(url).toPromise()
        .then(
          res => {
            resolve(res);
          }, rej => {
            reject(rej);
          }
        );
    });
    return pr;
  }

  public GetTransactionsForAccountHistory(accountNumber) {
    console.log('Getting Transactions By Account Number...');
    const url = this.url + 'api/Transaction/GetTransactionsForAccountHistory?acct=' + accountNumber;
    const pr = new Promise((resolve, reject) => {
      this.http.get(url).toPromise()
        .then(
          res => {
            console.log(res);
            resolve(res);
          }, rej => {
            reject(rej);
          }
        );
    });
    return pr;
  }

  public GetDistrictList(activeOnly) {
    console.log('getting districts...');
    const url = this.url + 'api/district/GetDistrictList?activeOnly='+activeOnly;
    // this.sharedService.CauseLoading();
    const pr = new Promise((resolve, reject) => {
      this.http.get(url).toPromise()
        .then(
          res => {
            resolve(res);
          }, rej => {
            reject(rej);
          }
        );
    });
    return pr;
  }

  public GetDistrictListForUser() {
    console.log('getting districts for User...');
    const url = this.url + 'api/district/GetDistrictListForUser';
    const pr = new Promise((resolve, reject) => {
      this.http.get(url).toPromise()
        .then(
          res => {
            resolve(res);
          }, rej => {
            reject(rej);
          }
        );
    });
    return pr;
  }

  public CheckCustomPropertyByDistrict(id) {
    console.log('getting CheckCustomPropertyByDistrict...');
    const url = this.url + 'api/CustomProperty/CheckCustomPropertyByDistrict?id=' + id.toString();
    const pr = new Promise((resolve, reject) => {
      this.http.get(url).toPromise()
        .then(
          res => {
            resolve(res);
          }, rej => {
            reject(rej);
          }
        );
    });
    return pr;
  }

  public GetDistrictInfo(id) {
    console.log('getting district info...');
    let url = this.url + 'api/district/GetDistrictById';
    url = url + '?id=' + id;
    // this.sharedService.CauseLoading();
    const pr = new Promise((resolve, reject) => {
      this.http.get(url).toPromise()
        .then(
          res => {
            // this.sharedService.StopLoading();
            resolve(res);
          }, rej => {
            // this.sharedService.StopLoading();
            reject(rej);
          }
        );
    });
    return pr;
  }

  public GetDistrictIdByAccountId(accountId) {
    console.log('getting district id from account id...');
    let url = this.url + 'api/district/GetDistrictIdByAccountId';
    url = url + '?accountId=' + accountId;
    const pr = new Promise((resolve, reject) => {
      this.http.get(url).toPromise()
        .then(
          res => {
            resolve(res);
          }, rej => {
            reject(rej);
          }
        );
    });
    return pr;
  }

  public SaveDistrict(district: District) {
    console.log('saving district...');
    const url = this.url + 'api/district/SaveDistrict';
    const pr = new Promise((resolve, reject) => {
      this.http.post(url, district).toPromise()
        .then(
          res => {
            resolve(res);
          }, rej => {
            reject(rej);
          }
        );
    });
    return pr;
  }

  public SaveBuilding(building: Building) {
    console.log('saving building...');
    const url = this.url + 'api/building/SaveBuilding';
    const pr = new Promise((resolve, reject) => {
      this.http.post(url, building).toPromise()
        .then(
          res => {
            resolve(res);
          }, rej => {
            reject(rej);
          }
        );
    });
    return pr;
  }

  public SaveAccount(account: AccountInfo) {
    console.log('saving account...');
    const url = this.url + 'api/accounts/SaveAccount';
    const pr = new Promise((resolve, reject) => {
      this.http.post(url, account).toPromise()
        .then(
          res => {
            resolve(res);
          }, rej => {
            reject(rej);
          }
        );
    });
    return pr;
  }

  public SaveContact(contact: Contact) {
    console.log('saving contact...');
    const url = this.url + 'api/contact/SaveContact';
    const pr = new Promise((resolve, reject) => {
      this.http.post(url, contact).toPromise()
        .then(
          res => {
            resolve(res);
          }, rej => {
            reject(rej);
          }
        );
    });
    return pr;
  }

  public DeleteContact(guid) {
    console.log('deleting contact...');
    const url = this.url + 'api/contact/DeleteContact?guid=' + guid;
    const pr = new Promise((resolve, reject) => {
      this.http.get(url).toPromise()
        .then(
          res => {
            resolve(res);
          }, rej => {
            reject(rej);
          }
        );
    });
    return pr;
  }

  public GetContactListByDistrictId(id) {
    console.log('getting contacts...');
    const url = this.url + 'api/contact/GetContactList?districtId=' + id;
    // this.sharedService.CauseLoading();
    const pr = new Promise((resolve, reject) => {
      this.http.get(url).toPromise()
        .then(
          res => {
            // this.sharedService.StopLoading();
            resolve(res);
          }, rej => {
            // this.sharedService.StopLoading();
            reject(rej);
          }
        );
    });
    return pr;
  }

  public GetContactInfoById(id) {
    console.log('getting contact info...');
    let url = this.url + 'api/contact/GetContactById';
    url = url + '?id=' + id;
    // this.sharedService.CauseLoading();
    const pr = new Promise((resolve, reject) => {
      this.http.get(url).toPromise()
        .then(
          res => {
            // this.sharedService.StopLoading();
            resolve(res);
          }, rej => {
            // this.sharedService.StopLoading();
            reject(rej);
          }
        );
    });
    return pr;
  }



  public GetBuildingListByDistrictId(id) {
    console.log('getting buildings...');
    const url = this.url + 'api/building/GetBuildingList?districtId=' + id;
    // this.sharedService.CauseLoading();
    const pr = new Promise((resolve, reject) => {
      this.http.get(url).toPromise()
        .then(
          res => {
            // this.sharedService.StopLoading();
            resolve(res);
          }, rej => {
            // this.sharedService.StopLoading();
            reject(rej);
          }
        );
    });
    return pr;
  }

  public GetCustomProperties(buildingId) {
    console.log('getting custom properties...');
    const url = this.url + 'api/CustomProperty/GetCustomPropertiesByBuilding?buildingId=' + buildingId.toString();
    const pr = new Promise((resolve, reject) => {
      this.http.get(url).toPromise()
        .then(
          res => {
            resolve(res);
          }, rej => {
            reject(rej);
          }
        );
    });
    return pr;
  }

  public GetCustomPropertyValues(propertyid) {
    if(!propertyid) {propertyid = '';}
    console.log('getting custom properties...');
    const url = this.url + 'api/CustomProperty/GetCustomPropertyValues?propertyId='+ propertyid.toString();
    const pr = new Promise((resolve, reject) => {
      this.http.get(url).toPromise()
        .then(
          res => {
            resolve(res);
          }, rej => {
            reject(rej);
          }
        );
    });
    return pr;
  }


  public GetBuildingInfoById(id) {
    console.log('getting building info...');
    let url = this.url + 'api/building/GetBuildingInfo';
    url = url + '?id=' + id;
    // this.sharedService.CauseLoading();
    const pr = new Promise((resolve, reject) => {
      this.http.get(url).toPromise()
        .then(
          res => {
            // this.sharedService.StopLoading();
            resolve(res);
          }, rej => {
            // this.sharedService.StopLoading();
            reject(rej);
          }
        );
    });
    return pr;
  }

  public GetBuildingAccountEditResources() {
    console.log('Getting BuildingAccountEditResources...');
    const url = this.url + 'api/building/GetBuildingAccountEditResources';
    const pr = new Promise((resolve, reject) => {
      this.http.get(url).toPromise()
        .then(
          res => {
            resolve(res);
          }, rej => {
            reject(rej);
          }
        );
    });
    return pr;
  }

  public GetLoadAreaList() {
    console.log('getting load areas...');
    const url = this.url + 'api/loadarea/GetLoadAreaList';
    // this.sharedService.CauseLoading();
    const pr = new Promise((resolve, reject) => {
      this.http.get(url).toPromise()
        .then(
          res => {
            // this.sharedService.StopLoading();
            resolve(res);
          }, rej => {
            // this.sharedService.StopLoading();
            reject(rej);
          }
        );
    });
    return pr;
  }

  public GetLoadAreaById(id) {
    console.log('getting load area by id...');
    let url = this.url + 'api/loadarea/GetLoadAreaById';
    url = url + '?id=' + id;
    // this.sharedService.CauseLoading();
    const pr = new Promise((resolve, reject) => {
      this.http.get(url).toPromise()
        .then(
          res => {
            // this.sharedService.StopLoading();
            resolve(res);
          }, rej => {
            // this.sharedService.StopLoading();
            reject(rej);
          }
        );
    });
    return pr;
  }

  public GetServiceClassList() {
    console.log('getting service classes...');
    const url = this.url + 'api/serviceclass/GetServiceClassList';
    // this.sharedService.CauseLoading();
    const pr = new Promise((resolve, reject) => {
      this.http.get(url).toPromise()
        .then(
          res => {
            // this.sharedService.StopLoading();
            resolve(res);
          }, rej => {
            // this.sharedService.StopLoading();
            reject(rej);
          }
        );
    });
    return pr;
  }

  public GetServiceClassById(id) {
    console.log('getting service class by id...');
    let url = this.url + 'api/serviceclass/GetServiceClassById';
    url = url + '?id=' + id;
    const pr = new Promise((resolve, reject) => {
      this.http.get(url).toPromise()
        .then(
          res => {
            resolve(res);
          }, rej => {
            reject(rej);
          }
        );
    });
    return pr;
  }

  public GetUnpostedCount() {
    console.log('getting unposted count...');
    const url = this.url + 'api/transaction/GetUnpostedCount';
    const pr = new Promise((resolve, reject) => {
      this.http.get(url).toPromise()
        .then(
          res => {
            resolve(res);
          }, rej => {
            reject(rej);
          }
        );
    });
    return pr;
  }

  public CheckPasswordExpire() {
    console.log('checking password expiration...');
    const url = this.url + 'api/user/CheckPasswordExpire';
    const pr = new Promise((resolve, reject) => {
      this.http.get(url).toPromise()
        .then(
          res => {
            resolve(res);
          }, rej => {
            reject(rej);
          }
        );
    });
    return pr;
  }



  public GetInstallmentsByAccount(accountNumber) {
    console.log('Getting installments by account...');
    const url = this.url + 'api/Installment/GetInstallmentsByAccount?acct='+accountNumber;
    const pr = new Promise((resolve, reject) => {
      this.http.get(url).toPromise()
        .then(
          res => {
            resolve(res);
          }, rej => {
            reject(rej);
          }
        );
    });
    return pr;
  }

  public GetInstallmentsByYear(yearid) {
    console.log('Getting installments by account...');
    const url = this.url + 'api/Installment/GetInstallmentsByYear?yr='+yearid.toString();
    const pr = new Promise((resolve, reject) => {
      this.http.get(url).toPromise()
        .then(
          res => {
            resolve(res);
          }, rej => {
            reject(rej);
          }
        );
    });
    return pr;
  }

  public GetInstallmentsByInvoice(invoice) {
    console.log('Getting installments by invoice...');
    const url = this.url + 'api/Installment/GetInstallmentsByInvoice?invoice='+invoice;
    const pr = new Promise((resolve, reject) => {
      this.http.get(url).toPromise()
        .then(
          res => {
            resolve(res);
          }, rej => {
            reject(rej);
          }
        );
    });
    return pr;
  }

  public GetInstallmentById(id) {
    console.log('Getting installment...');
    const url = this.url + 'api/Installment/GetInstallmentById?id='+id;
    const pr = new Promise((resolve, reject) => {
      this.http.get(url).toPromise()
        .then(
          res => {
            resolve(res);
          }, rej => {
            reject(rej);
          }
        );
    });
    return pr;
  }

  public SaveInstallment(installment: Installment) {
    console.log('saving installment...');
    const url = this.url + 'api/installment/SaveInstallment';
    const pr = new Promise((resolve, reject) => {
      this.http.post(url, installment).toPromise()
        .then(
          res => {
            resolve(res);
          }, rej => {
            reject(rej);
          }
        );
    });
    return pr;
  }

  public DeleteInstallmentById(id) {
    console.log('deleting installment...');
    const url = this.url + 'api/installment/DeleteInstallment?id='+id;
    const pr = new Promise((resolve, reject) => {
      this.http.get(url).toPromise()
        .then(
          res => {
            resolve(res);
          }, rej => {
            reject(rej);
          }
        );
    });
    return pr;
  }

  public SaveServiceClass(sc: ServiceClass) {
    console.log(sc);
    console.log('saving service class...');
    const url = this.url + 'api/serviceclass/SaveServiceClass';
    const pr = new Promise((resolve, reject) => {
      this.http.post(url, sc).toPromise()
        .then(
          res => {
            resolve(res);
          }, rej => {
            reject(rej);
          }
        );
    });
    return pr;
  }

  public SaveCustomPropertyValue(cpv: CustomPropertyValue) {
    console.log('saving custom property value...');
    const url = this.url + 'api/CustomProperty/SaveCustomPropertyValue';
    const pr = new Promise((resolve, reject) => {
      this.http.post(url, cpv).toPromise()
        .then(
          res => {
            resolve(res);
          }, rej => {
            reject(rej);
          }
        );
    });
    return pr;
  }


  public DeleteServiceClass(id: number) {
    console.log('delete service class...');
    const url = this.url + 'api/serviceclass/DeleteServiceClass?id=' + id;
    const pr = new Promise((resolve, reject) => {
      this.http.get(url).toPromise()
        .then(
          res => {
            resolve(res);
          }, rej => {
            reject(rej);
          }
        );
    });
    return pr;
  }

  public SaveLoadArea(sc: LoadArea) {
    console.log('saving load area...');
    const url = this.url + 'api/loadarea/SaveLoadArea';
    const pr = new Promise((resolve, reject) => {
      this.http.post(url, sc).toPromise()
        .then(
          res => {
            resolve(res);
          }, rej => {
            reject(rej);
          }
        );
    });
    return pr;
  }

  public DeleteLoadArea(id: number) {
    console.log('delete load area...');
    const url = this.url + 'api/loadarea/DeleteLoadArea?id=' + id;
    const pr = new Promise((resolve, reject) => {
      this.http.get(url).toPromise()
        .then(
          res => {
            resolve(res);
          }, rej => {
            reject(rej);
          }
        );
    });
    return pr;
  }

  public TransferTransactionOwnership(id: string, owner: string) {
    console.log('transferring transaction ownership...');
    const url = this.url + 'api/transaction/SetTransactionOwner?id=' + id + '&owner=' + owner;
    const pr = new Promise((resolve, reject) => {
      this.http.get(url).toPromise()
        .then(
          res => {
            resolve(res);
          }, rej => {
            reject(rej);
          }
        );
    });
    return pr;
  }
  public GetUserNamesForYear(ActiveYear?:number) {
    console.log('getting user names...');
    let url = this.url + 'api/user/GetUserNames';
    if(ActiveYear>0){url = url + '/' + ActiveYear;    }
    const pr = new Promise((resolve, reject) => {
      this.http.get(url).toPromise()
        .then(
          res => {
            resolve(res);
          }, rej => {
            reject(rej);
          }
        );
    });
    return pr;
  }

  public GetUserNames() {
    console.log('getting user names...');
    const url = this.url + 'api/user/GetUserNames';
    const pr = new Promise((resolve, reject) => {
      this.http.get(url).toPromise()
        .then(
          res => {
            resolve(res);
          }, rej => {
            reject(rej);
          }
        );
    });
    return pr;
  }

  public GetBatchNumbers(start:Date, end:Date, user:string) {
    console.log('getting batch numbers...');
    const startString = start.toDateString();
    const endString = end.toDateString();
    let url = this.url + 'api/Transaction/GetBatchNumbers';
    url += '?startdate=' + startString;
    url += '&enddate=' + endString;
    url += '&username=' + user;
    const pr = new Promise((resolve, reject) => {
      this.http.get(url).toPromise()
        .then(
          res => {
            resolve(res);
          }, rej => {
            reject(rej);
          }
        );
    });
    return pr;
  }

  public PostForProvider(providerid) {
    console.log('posting for provider...');
    const url = this.url + 'api/providers/PostForProvider?id=' + providerid;
    const pr = new Promise((resolve, reject) => {
      this.http.get(url).toPromise()
        .then(
          res => {
            resolve(res);
          }, rej => {
            reject(rej);
          }
        );
    });
    return pr;
  }

  public GenerateReport(dto: GenerateReportDTO) {
    console.log('generating report...');
    const url = this.url + 'api/report/GenerateReport';
    const pr = new Promise((resolve, reject) => {
      this.http.post(url, dto).toPromise()
        .then(
          res => {
            resolve(res);
          }, rej => {
            reject(rej);
          }
        );
    });
    return pr;
  }


  public GetAllContactPositions() {
    console.log('getting all contact positions');
    const url = this.url + 'api/contact/GetAllContactPositions';
    const pr = new Promise((resolve, reject) => {
      this.http.get(url).toPromise()
        .then(
          res => {
            resolve(res);
          }, rej => {
            reject(rej);
          }
        );
    });
    return pr;
  }

  public GetAllYears() {
    console.log('getting year list...');
    const url = this.url + 'api/years/GetAllYears';
    const pr = new Promise((resolve, reject) => {
      this.http.get(url).toPromise()
        .then(
          res => {
            resolve(res);
          }, rej => {
            reject(rej);
          }
        );
    });
    return pr;
  }

  public GetAllFiltersets() {
    console.log('getting filterset list...');
    const url = this.url + 'api/filterset/GetFilterSetList';
    const pr = new Promise((resolve, reject) => {
      this.http.get(url).toPromise()
        .then(
          res => {
            resolve(res);
          }, rej => {
            reject(rej);
          }
        );
    });
    return pr;
  }

  public DeleteTransaction(id) {
    console.log('deleting transaction...');
    const url = this.url + 'api/transaction/DeleteTransaction?id='+id;
    const pr = new Promise((resolve, reject) => {
      this.http.get(url).toPromise()
        .then(
          res => {
            resolve(res);
          }, rej => {
            reject(rej);
          }
        );
    });
    return pr;
  }

  public GetMessageOfTheDay() {
    console.log('getting motd...');
    const url = this.url + 'api/app/GetMotD';
    const pr = new Promise((resolve, reject) => {
      this.http.get(url).toPromise()
        .then(
          res => {
            resolve(res);
          }, rej => {
            reject(rej);
          }
        );
    });
    return pr;
  }

  public GetPortalMessageOfTheDay() {
    console.log('getting portal motd...');
    const url = this.url + 'api/app/GetPortalMotD';
    const pr = new Promise((resolve, reject) => {
      this.http.get(url).toPromise()
        .then(
          res => {
            resolve(res);
          }, rej => {
            reject(rej);
          }
        );
    });
    return pr;
  }

  public SaveMessageOfTheDay(msg) {
    console.log('saving motd...');
    const url = this.url + 'api/app/SaveMotD';
    const pr = new Promise((resolve, reject) => {
      this.http.post(url, {'msg': msg}).toPromise()
        .then(
          res => {
            resolve(res);
          }, rej => {
            reject(rej);
          }
        );
    });
    return pr;
  }

  public SavePortalMessageOfTheDay(msg) {
    console.log('saving portal motd...');
    const url = this.url + 'api/app/SavePortalMotD';
    const pr = new Promise((resolve, reject) => {
      this.http.post(url, {'msg': msg}).toPromise()
        .then(
          res => {
            resolve(res);
          }, rej => {
            reject(rej);
          }
        );
    });
    return pr;
  }


  public GetBuildingAccountTransactionDependants(acct) {
    console.log('getting GetBuildingAccountTransactionDependants...', acct);
    const url = this.url + 'api/Accounts/GetBuildingAccountTransactionDependants?accountid='+acct;
    const pr = new Promise((resolve, reject) => {
      this.http.get(url).toPromise().then(
        res => {
          resolve(res);
        }, rej => {
          reject(rej);
        });
    });
    return pr;
  }


  public GetBuildingAccountInstallmentDependants(acct) {
    console.log('getting GetBuildingAccountInstallmentDependants...', acct);
    const url = this.url + 'api/Accounts/GetBuildingAccountInstallmentDependants?accountid='+acct;
    const pr = new Promise((resolve, reject) => {
      this.http.get(url).toPromise().then(
        res => {
          resolve(res);
        }, rej => {
          reject(rej);
        });
    });
    return pr;
  }

  public DeactivateAccount(acct) {
    console.log('Deactivating Account...', acct);
    const url = this.url + 'api/Accounts/DeactivateAccount?accountid='+acct;
    const pr = new Promise((resolve, reject) => {
      this.http.get(url).toPromise().then(
        res => {
          resolve(res);
        }, rej => {
          reject(rej);
        });
    });
    return pr;
  }

  public ActivateAccount(acct) {
    console.log('Activating Account...', acct);
    const url = this.url + 'api/Accounts/ActivateAccount?accountid='+acct;
    const pr = new Promise((resolve, reject) => {
      this.http.get(url).toPromise().then(
        res => {
          resolve(res);
        }, rej => {
          reject(rej);
        });
    });
    return pr;
  }

  public DeactivateBuilding(id) {
    console.log('Deactivating building...', id);
    const url = this.url + 'api/building/DeactivateBuilding?id='+id;
    const pr = new Promise((resolve, reject) => {
      this.http.get(url).toPromise().then(
        res => {
          resolve(res);
        }, rej => {
          reject(rej);
        });
    });
    return pr;
  }

  public ActivateBuilding(id) {
    console.log('Activating building...', id);
    const url = this.url + 'api/building/ActivateBuilding?id='+id;
    const pr = new Promise((resolve, reject) => {
      this.http.get(url).toPromise().then(
        res => {
          resolve(res);
        }, rej => {
          reject(rej);
        });
    });
    return pr;
  }

  public DeactivateDistrict(id) {
    console.log('Deactivating district...', id);
    const url = this.url + 'api/district/DeactivateDistrict?id='+id;
    const pr = new Promise((resolve, reject) => {
      this.http.get(url).toPromise().then(
        res => {
          resolve(res);
        }, rej => {
          reject(rej);
        });
    });
    return pr;
  }

  public ActivateDistrict(id) {
    console.log('Activating district...', id);
    const url = this.url + 'api/district/ActivateDistrict?id='+id;
    const pr = new Promise((resolve, reject) => {
      this.http.get(url).toPromise().then(
        res => {
          resolve(res);
        }, rej => {
          reject(rej);
        });
    });
    return pr;
  }

  public GetFiscalPref() {
    console.log('Getting Fiscal Pref...');
    const url = this.url + 'api/User/GetFiscalPref';
    const pr = new Promise((resolve, reject) => {
      this.http.get(url).toPromise().then(
        res => {
          resolve(res);
        }, rej => {
          reject(rej);
        });
    });
    return pr;
  }

  public SetFiscalPref(val) {
    console.log('Setting Fiscal Pref...');
    const url = this.url + 'api/User/SetFiscalPref?val='+val;
    const pr = new Promise((resolve, reject) => {
      this.http.get(url).toPromise().then(
        res => {
          resolve(res);
        }, rej => {
          reject(rej);
        });
    });
    return pr;
  }















  //ADMIN PANEL STUFF

  public Admin_GetRoles() {
    console.log('getting roles...');
    const url = this.url + 'api/Admin/GetRoles';
    const pr = new Promise((resolve, reject) => {
      this.http.get(url).toPromise()
        .then(
          res => {
            resolve(res);
          }, rej => {
            reject(rej);
          }
        );
    });
    return pr;
  }

  public Admin_GetUsersByRole(roleid) {
    console.log('getting users by role...');
    const url = this.url + 'api/Admin/GetUsersByRole?roleid=' + roleid;
    const pr = new Promise((resolve, reject) => {
      this.http.get(url).toPromise()
        .then(
          res => {
            resolve(res);
          }, rej => {
            reject(rej);
          }
        );
    });
    return pr;
  }

  public Admin_SaveUser(user) {
    console.log('saving user');
    const url = this.url + 'api/Admin/SaveUser';
    const pr = new Promise((resolve, reject) => {
      this.http.post(url, user).toPromise()
        .then(
          res => {
            resolve(res);
          }, rej => {
            reject(rej);
          }
        );
    });
    return pr;
  }

  public Admin_UnlockUser(userid) {
    console.log('unblocking user...');
    const url = this.url + 'api/Admin/UnlockUser?id=' + userid;
    const pr = new Promise((resolve, reject) => {
      this.http.get(url).toPromise()
        .then(
          res => {
            resolve(res);
          }, rej => {
            reject(rej);
          }
        );
    });
    return pr;
  }

  public Admin_SaveYear(year) {
    console.log('saving year');
    const url = this.url + 'api/Years/SaveYear';
    const pr = new Promise((resolve, reject) => {
      this.http.post(url, year).toPromise()
        .then(
          res => {
            resolve(res);
          }, rej => {
            reject(rej);
          }
        );
    });
    return pr;
  }

  public Admin_GetYearAccessList() {
    console.log('getting year access list');
    const url = this.url + 'api/Admin/GetYearAccessList';
    const pr = new Promise((resolve, reject) => {
      this.http.get(url).toPromise()
        .then(
          res => {
            resolve(res);
          }, rej => {
            reject(rej);
          }
        );
    });
    return pr;
  }

  public Admin_SaveYearAccess(yraccess: YearAccess) {
    console.log('saving year access');
    const url = this.url + 'api/Admin/SaveYearAccess';
    const pr = new Promise((resolve, reject) => {
      this.http.post(url, yraccess).toPromise()
        .then(
          res => {
            resolve(res);
          }, rej => {
            reject(rej);
          }
        );
    });
    return pr;
  }





  public GetUserSettings() {
    console.log('getting user settings');
    const url = this.url + 'api/User/GetUserSettings';
    const pr = new Promise((resolve, reject) => {
      this.http.get(url).toPromise()
        .then(
          res => {
            resolve(res);
          }, rej => {
            reject(rej);
          }
        );
    });
    return pr;
  }

  public SaveUserSettings(dto) {
    console.log('saving user settings');
    const url = this.url + 'api/Admin/UpdateUserSettings';
    const pr = new Promise((resolve, reject) => {
      this.http.post(url, dto).toPromise()
        .then(
          res => {
            resolve(res);
          }, rej => {
            reject(rej);
          }
        );
    });
    return pr;
  }

  public GetAccountIdList() {
    console.log('Getting Account ID List...');
    const url = this.url + 'api/Accounts/GetAccountIdList';
    const pr = new Promise((resolve, reject) => {
      this.http.get(url).toPromise()
        .then(
          res => {
            resolve(res);
          }, rej => {
            reject(rej);
          }
        );
    });
    return pr;
  }

  public GetAllProviders() {
    console.log('getting all providers...');
    const url = this.url + 'api/providers/getall';
    const pr = new Promise((resolve, reject) => {
      this.http.get(url).toPromise()
        .then(
          res => {
            resolve(res);
          }, rej => {
            reject(rej);
          }
        );
    });
    return pr;
  }

  public GetExpectationForTransaction(accountid, transtype, cycledate) {
    console.log('getting expectations');
    const url = this.url + 'api/Transaction/GetExpectationForTransaction?acct='+accountid+'&tType='+transtype+"&dt="+cycledate;
    const pr = new Promise((resolve, reject) => {
      this.http.get(url).toPromise()
        .then(
          res => {
            resolve(res);
          }, rej => {
            reject(rej);
          }
        );
    });
    return pr;
  }














  //REPORTS PORTAL

  public GetFilterSetsByDistrictId(id)
  {
    console.log('Getting Filter Sets...');
    const url = this.url + "api/FilterSet/GetFilterSetByDistrictId?id=" + id;
    const pr = new Promise( (resolve, reject) =>
    {
      this.http.get(url).toPromise()
        .then(
          res => {
            resolve(res);
          }, rej => {
            reject(rej);
          }
        );
    });
    return pr;
  }

  public GetAccountListForFilterSet(guid)
  {
    console.log('Getting Filter Sets...');
    const url = this.url + "api/Accounts/GetAccountIdListByFilterSet?guid=" + guid;
    const pr = new Promise( (resolve, reject) =>
    {
      this.http.get(url).toPromise()
        .then(
          res => {
            resolve(res);
          }, rej => {
            reject(rej);
          }
        );
    });
    return pr;
  }

  public GetAccountListNotInFilterSet(filtersetid, districtid)
  {
    console.log('Getting Filter Sets...');
    const url = this.url + "api/accounts/GetAccountListNotInFilterSet?fsid="+filtersetid+'&did='+districtid;
    const pr = new Promise( (resolve, reject) =>
    {
      this.http.get(url).toPromise()
        .then(
          res => {
            resolve(res);
          }, rej => {
            reject(rej);
          }
        );
    });
    return pr;
  }

  public AddNewFilterSet(filterset)
  {
    console.log('Adding Filter Set...');
    const url = this.url + "api/FilterSet/CreateFilterSet";
    const pr = new Promise( (resolve, reject) =>
    {
      this.http.post(url, filterset).toPromise()
        .then(
          res => {
            resolve(res);
          }, rej => {
            reject(rej);
          }
        );
    });
    return pr;
  }

  public DeleteFilterSet(guid)
  {
    console.log('Deleting Filter Set...');
    const url = this.url + "api/ReportService/DeleteFilterSet";
    const dto = {
      guid: guid
    };
    const pr = new Promise( (resolve, reject) =>
    {
      this.http.post(url, dto, {withCredentials: true})
        .toPromise()
        .then(
          res => {
            resolve(res);
          }, rej => {
            reject(rej);
          }
        );
    });
    return pr;
  }

  public AddAccountsToFilterSet(accountId, filterSetId)
  {
    console.log('Adding Account To Filter Set...');
    const url = this.url + "api/Accounts/AddAccountToFilterSet?fsid=" + filterSetId + '&acct=' + accountId;
    const pr = new Promise( (resolve, reject) =>
    {
      this.http.get(url).toPromise()
        .then(
          res => {
            resolve(res);
          }, rej => {
            reject(rej);
          }
        );
    });
    return pr;
  }

  public RemoveAccountsFromFilterSet(accountId, filterSetId)
  {
    console.log('Remove Account From Filter Set...');
    const url = this.url + "api/Accounts/RemoveAccountFromFilterSet?fsid=" + filterSetId + '&acct=' + accountId;
    const pr = new Promise( (resolve, reject) =>
    {
      this.http.get(url).toPromise()
        .then(
          res => {
            resolve(res);
          }, rej => {
            reject(rej);
          }
        );
    });
    return pr;
  }

  public GetYearListForDistrictId(districtId)
  {
    console.log(`'getting years for district ${districtId}...'`);
    const url = this.url + `api/Years/GetYearListForDistrictId?id=${districtId}`;
    const pr = new Promise((resolve, reject) => {
      this.http.get(url).toPromise()
        .then(
          res => {
            resolve(res);
          }, rej => {
            reject(rej);
          }
        );
    });
    return pr;
  }

  public GetAccountIdListByFilterSet(id)
  {
    console.log('getting accounts by filterset');
    const url = this.url + 'api/accounts/GetAccountIdListByFilterSet?guid=' + id;
    const pr = new Promise((resolve, reject) => {
      this.http.get(url).toPromise()
        .then(
          res => {
            resolve(res);
          }, rej => {
            reject(rej);
          }
        );
    });
    return pr;
  }

  public GeneratePortalReport(generateReportDTO)
  {
    console.log('Generating portal report...');
    const url = this.url + "api/report/GeneratePortalReport";
    const pr = new Promise( (resolve, reject) =>
    {
      this.http.post(url, generateReportDTO).toPromise()
        .then(
          res => {
            resolve(res);
          }, rej => {
            reject(rej);
          }
        );
    });
    return pr;
  }


























//OLD STUFF




















  public ToastSuccess(msg){
    this.toastSuccess.next(msg);
    console.log('%c' + msg, "color: green");
  }

  public ToastSingleSuccess(msg){
      this.toastOneTimeSuccess.next(msg);
      console.log('%c' + msg, "color: green");
    }

  public ToastError(msg){
      this.toastError.next(msg);
      console.log('%c' + msg, "color: red");
  }

  public ToastInfo(msg){
      this.toastInfo.next(msg);
      console.log('%c' + msg, "color: blue");
  }

  public ToastWarning(msg){
      this.toastWarning.next(msg);
      console.log('%c' + msg, "color: goldenrod");
  }

  public ToastSingleWarning(msg){
    this.toastOneTimeWarning.next(msg);
    console.log('%c' + msg, "color: goldenrod");
}

  public ToastClear(msg){
      this.toastClear.next(msg);
  }

}
