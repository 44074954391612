
import { HttpClient } from '@angular/common/http';
import { PlatformLocation } from '@angular/common';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { APP_CONFIG } from './ApplicationConfig.service';
import { ValueConverter } from '@angular/compiler/src/render3/view/template';

@Injectable({
  providedIn: 'root'
})
export class FeatureFlagsService {

  featureFlags= [];
  //featureFlags: JSON;
  updateTime = new Date(2000,0,1);

  constructor(public httpClient: HttpClient ,private pfl: PlatformLocation,) { }

  loadFeatureFlags(): Promise<any> {
    var url =  APP_CONFIG.url;
    url += "api/GetFlags";
    this.updateTime = new Date();
    return this.httpClient.get(url)
 //   .pipe(tap((response) => this.featureFlags = response as any))
    .pipe(tap((response) => this.featureFlags =  response as any))
    .toPromise(); 
  }

  getFeatureFlag(key: string): boolean { 
    if (this.updateTime < new Date(2001,1,2))
    {
      this.loadFeatureFlags();
      return false;
    }  
    else
    {
      let flag= this.featureFlags.find(x => x.feature === key) ;
      if (!flag) 
      {
        return false;
      } 
      else
      {
        return flag.enabled;
      }  
    }
  }

}