import { Status } from './Status';

export class District {
  public numDistrictId: number;
  public strDistrictName = '';
  public strStreet = '';
  public strCity = '';
  public strState = '';
  public strZip = '';
  public dtmDateEntered: Date;
  public dtmDateLeft: Date;
  public numStatusId: number;
  public numLYFA: number;
  public numServiceClassId: number;
  public numLoadAreaId: number;
  public numRegionId: number;
  public strFederalID = '';
  public status: Status = new Status();
  public accountDependants = 0;
  public buildingDependants = 0;
  public installmentDependants = 0;
  public transactionDependants = 0;

}

export class DistrictListItem {
  public numDistrictId: number;
  public strDistrictName = '';
}

