import { Injectable } from "@angular/core";
import { Year } from '../models/Year';


@Injectable({
  providedIn: "root"
})
export class SharedService {
  static Instance: SharedService;
  public appstatus = 'Login Completed';
  public activeYearId;
  public activeYearDescr = '';
  public activeYearAccess = 'R';
  public activeYearStart:Date;
  public activeYearEnd:Date;
  public role = '';

  private chimes = new Audio();

  constructor() {
    SharedService.Instance = this;
    this.chimes = new Audio('../../../assets/sounds/fingercymbalschime.mp3');   
    this.chimes.load();
  }

  static UpdateStatus(s) {
    SharedService.Instance.appstatus = s;
  }

  static GetActiveYearId() {
    const yr = sessionStorage.getItem('activeYearId');
    let num = 0;
    if(yr) {
      num = Number(yr);
    }
    return num;
  }

  static GetUsername() {
    const un = sessionStorage.getItem('Auth_Username');
    return un;
  }

  public SetRequestedApp() {
    sessionStorage.setItem('RequestedApplication', 'App');
    console.log('requested app');
  }

  public SetRequestedReports() {
    sessionStorage.setItem('RequestedApplication', 'Reports');
    console.log('requested report portal');
  }

  public SetYear(year: Year) {
    sessionStorage.setItem('activeYearDescr', year.strYearDescription);
    sessionStorage.setItem('activeYearAccess', year.Access);
    sessionStorage.setItem('activeYearId', year.numYearId.toString());
    sessionStorage.setItem('activeYearStart', year.dtmStart.toString());
    sessionStorage.setItem('activeYearEnd', year.dtmEnd.toString());
    this.activeYearDescr = year.strYearDescription;
    this.activeYearAccess = year.Access;
    this.activeYearId = year.numYearId;
  }

  public ApplyActiveYear() {
    this.activeYearDescr = sessionStorage.getItem('activeYearDescr');
    this.activeYearAccess = sessionStorage.getItem('activeYearAccess');
    this.activeYearId = sessionStorage.getItem('activeYearId');
    const activeYearStartString = sessionStorage.getItem('activeYearStart');
    const activeYearEndString = sessionStorage.getItem('activeYearEnd');
    this.activeYearStart = activeYearStartString ? new Date(activeYearStartString) : null;
    this.activeYearEnd = activeYearEndString ? new Date(activeYearEndString) : null;
  }

  public SetRole(role) {
    sessionStorage.setItem('role', role);
    this.role = role;
  }

  public ApplyRole() {
    this.role = sessionStorage.getItem('role');
  }

  public LoadCymbalsChime(){
    this.chimes.load();
  }
  public PlayCymbalsChime(){
    this.chimes.play();
  }





}
