import { Injectable, OnInit, OnDestroy } from "@angular/core";
import { AuthService } from '../services/auth.service';
import { timer, Observable, Subject, asapScheduler, pipe, of, from, interval, merge, fromEvent } from 'rxjs';
import {take, map, filter, scan } from 'rxjs/operators';
import { Pipe, PipeTransform } from '@angular/core';

@Injectable()
export class PingService {
// this will work on an interval that sends a ping to the api controller to refresh its owin token every so often.

  public useRefreshTokens = true;
  private refreshExtension = 5; // lead way time for refresh token to refresh before it expires in minutes.
  private tick = 10000;
  private pingInterval = interval(this.tick);
  private subscription;

  constructor(private auth: AuthService) {
    this.subscription = this.pingInterval.subscribe(x=> this.DoPing());
  }

  public DoPing() {
    //console.log('ping');
    if(this.useRefreshTokens) {
      this.CheckRefreshToken();
    }
  }

  private CheckRefreshToken() {
    // if(!this.auth.isRefreshing()){
    if(!this.auth.isRefreshing){
      // This will check if our refresh token is going to expire soon. If it does, we can submit a request to refresh it beforehand.
      const expires = sessionStorage.getItem('Auth_ExpiresAt');
      const expiresDt = expires ? new Date(expires) : new Date();
      let currentDt = new Date();
      currentDt = this.addMinutes(currentDt, this.refreshExtension);
      if(currentDt > expiresDt) {
        this.auth.RefreshToken();
      }
    }
  }

  private addMinutes(date, minutes) {
    return new Date(date.getTime() + minutes*60000);
}

}
