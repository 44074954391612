export class Provider {
  public id = -1;
  public name = '';
  public street = '';
  public city = '';
  public state = '';
  public zip = '';
  public contactfirst = '';
  public contactlast= '';
  public phone = '';
  public cell = '';
  public email = '';
  public fax = '';
  public effective: Date;
}

