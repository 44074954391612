import { NgModule, APP_INITIALIZER } from '@angular/core';
import { ApplicationConfigService} from './services/ApplicationConfig.service';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { IQEComboboxModule, IQEControlsModule, IQEButtonModule, IQETextboxModule } from 'iqe-controls-lib';
import { LoginComponent } from './login/login.component';
import { DataService } from './services/dataservice.service';
import { AuthService } from './services/auth.service';
import { AppRoutingModule } from './app-routing.module';
import { ReportPortalController } from './layout_portal/controllers/reportportal.controller';
import { TokenInterceptor } from './interceptor/token.interceptor';
import { MessageService} from 'primeng/components/common/messageservice';
import { ToastModule} from 'primeng/toast';
import { LayoutAppComponent } from './layout_app/layout_app.component';
import { LayoutPortalComponent } from './layout_portal/layout_portal.component';
import { AuthGuard } from './guard';
import { RegistrationComponent } from './registration/registration.component';

import { HashLocationStrategy, LocationStrategy } from '@angular/common';

import { LowerCaseUrlSerializer } from './interceptor/LowerCaseUrlSerializer';
import { UrlSerializer } from '@angular/router';
import { SharedService } from './services/shared.service';
import { CalendarModule } from '../../node_modules/primeng/calendar';
import { BrowserAnimationsModule } from '../../node_modules/@angular/platform-browser/animations';

import { AgGridModule } from 'ag-grid-angular';
import { PingService } from './auth/ping.service';
import { ReportAuthGuard } from './guard/reportauth.guard';
import { LoadingScreenInterceptor } from './interceptor/loading-screen.interceptor';
import { LoadingScreenComponent } from './components/loading-screen/loading-screen.component';
import { ForgotPasswordComponent } from './components/forgotpassword/forgotpassword.component';
import { ResetPasswordComponent } from './resetpassword/resetpassword.component';
import { FeatureFlagsService } from './services/feature-flags.service';
// import { EditorModule } from '@tinymce/tinymce-angular';
// import { SessionManagerComponent } from './components/sessionmanager/sessionmanager.component';
// import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
// import { faCoffee } from '@fortawesome/free-solid-svg-icons';
// import { faPencil } from '@fortawesome/free-solid-svg-icons';
// import { faTrash } from '@fortawesome/free-solid-svg-icons';




export function loadConfigService(configService: ApplicationConfigService): Function
{
  return () => configService.load();
}




@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RegistrationComponent,
    LoadingScreenComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    NgbModule,
    IQEControlsModule,
    IQEComboboxModule,
    IQEButtonModule,
    IQETextboxModule,
    FormsModule,
    HttpClientModule,
    AppRoutingModule,
    CalendarModule,
    ToastModule,
    AgGridModule.withComponents([]),
    // EditorModule
    //FontAwesomeModule
  ],
  providers: [    
    {provide: LocationStrategy, useClass: HashLocationStrategy},
    AuthGuard,
    ReportAuthGuard,
    PingService,
    MessageService,
    ApplicationConfigService,
    { provide: APP_INITIALIZER, useFactory: loadConfigService , deps: [ApplicationConfigService], multi: true },
    DataService,
    AuthService,
    SharedService,
    ReportPortalController,
    {provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: LoadingScreenInterceptor, multi: true},
    {provide: UrlSerializer, useClass: LowerCaseUrlSerializer },   
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  // faCoffee = faCoffee; 
  // faPencil = faPencil; 
  // faTrash = faTrash; 
}
