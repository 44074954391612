import { throwError,  Observable, interval } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { HttpRequest, HttpHandler, HttpInterceptor } from '@angular/common/http';
import { AuthService } from '../services/auth.service';
import { AppController } from '../layout_app/controllers/app.controller';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    static RefreshRequest = ''; // the request being called that is retrying to refresh the token. // this is the url of a request that we "re-tried". If it fails again, then give up.
    static RefreshRequestTick = 0; // Its okay to retry an unauth request when the request happened more than a minute ago.
    static FailedRequests: string[] = [];
    static isRefreshing: boolean = false; // If we are already refreshing our token and another request comes through
                // we need to somehow holds up new requests until our refresh is done.
    public multiRequestTokenTimeout = 10; // Amount of times / seconds other requests will wait for a token to come back when multi requesting
    authService: AuthService;
    appController: AppController;


    //URLS that will NOT renew a sessions activity timeout.
    skippUrls = [
      '/token',
    ];



    constructor(public inj: Injector) {
        // console.log('Constructed Interceptor');
    }

    private SetAuthService(){
        if(!this.authService){
            this.authService = this.inj.get(AuthService);
        }
    }

    private SetAppController(){
      if(!this.appController){
          // console.log('setting appcontroller: ' , AppController.Instance);
          if(AppController.Instance) {
            this.appController = AppController.Instance;
          }
      }
  }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any>{
      const originalUrl = request.url; // original URL used for this request
      if(request.url && request.url.indexOf('http') === -1 && request.url[0] === '/' || request.url.indexOf('/token') !== -1) {
        return next.handle(request);
      }
      else{
          // console.log('intercepted ' , request);
          this.CheckSessionIdle(request);
          this.SetAuthService();
          if(!this.authService.isProd){ console.log(request.url); } // console log request if debugging.
          const token = this.authService.GetToken();
          let cloneurl = originalUrl;
          if ((request.method === 'GET') && request.url.indexOf('noCache') === -1 && (!request.url.match(/html$/))){
              const separator = request.url.indexOf('?') === -1 ? '?' : '&';
              cloneurl = originalUrl + separator + 'noCache=' + new Date().getTime();
          }
          if(token){
              request = request.clone({
                  setHeaders: {
                      Authorization: `Bearer ${token}`
                  },
                  url:cloneurl
              });
          }
          // console.log('sending out', request);
          return next.handle(request).pipe(catchError(errorResponse => {
            if(errorResponse.status === 403 || errorResponse.status === 401){
              // forbidden means we arent logged in, boot back to login screen.
              this.authService.ForceLogout();
            }
            return throwError(errorResponse);
          }));
      }
    }


    public delay(ms: number) {
        return new Promise(res => setTimeout(res, ms) );
    }




    public CheckSessionIdle(request: HttpRequest<any>) {
      //If we are making a service call that did not originate from the token auto pinger (refreshToken)
      //then we can extend our expiration idle timeout.
      const url = request.url;
      let updateActivity = true;


      //look through urls to skip if we have a match then donot update timeout activity.
      for (const skippUrl of this.skippUrls) {
        if (new RegExp(skippUrl).test(request.url)) {
          updateActivity = false;
          break;
        }
      }


      if(updateActivity) {
        this.SetAppController();
        if(this.appController) {
          this.appController.UpdateTimeout();
        }
      }
    }





}

