export class AcctSupplier {

    public id:number;
    public name = '';
    public street = '';
    public city = '';
    public state = '';
    public zip = '';
    public contactfirst = '';
    public contactlast = '';
    public phone = '';
    public cell = '';
    public email = '';
    public fax  = '';   
    public effective: Date;
   
  }

  

  export class tblAcctSupplier {

    public numAcctSupplierId:number;
    public strAccountId = '';
    public numSupplierId:number;
    public dtmAsOfDate: Date;    
   
    public  FromFullSupplier(acct: string, sup: AcctSupplier){
        this.numAcctSupplierId = sup.id;
        this.numSupplierId= sup.id;
       // this.dtmAsOfDate = this.FixDate(sup.effective);
       this.dtmAsOfDate = sup.effective;
       this.strAccountId = acct;

    }
    private FixDate(t:Date){
      console.log('datein: '+t );
      if (t){
        t = new Date(t.getUTCFullYear(),t.getMonth(),t.getUTCDate(),t.getTimezoneOffset()/-60,0,0);
        console.log('dateout: '+t );
      }  //| formatDate:'MM/dd/yyyy'
      
      return t;
    }

    public FromFullSupplierList(acct:string, supList: AcctSupplier[]){
       let tblASL: tblAcctSupplier[] = [] ;
       if (supList != null){
          supList.forEach ( (x )=> {
          let newTASL:tblAcctSupplier = new tblAcctSupplier();
          newTASL.FromFullSupplier(acct, x);
          let ds:string = newTASL.dtmAsOfDate.getFullYear()
              +"-"+ String(newTASL.dtmAsOfDate.getMonth()+1).padStart(2,'0')
              +"-"+ String(newTASL.dtmAsOfDate.getDate()).padStart(2,'0');
   
          newTASL.dtmAsOfDate =  new Date(ds);
          
          tblASL.push(newTASL );
          });  
  
       }
       return tblASL;
    }
  }
