import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { APP_CONFIG } from './ApplicationConfig.service';
import { ReportPortalController } from '../layout_portal/controllers/reportportal.controller';
import { Router } from '@angular/router';
import { SharedService } from './shared.service';
import { Year } from '../models/Year';
import { AppController } from '../layout_app/controllers/app.controller';
import { PageController } from '../layout_app/controllers/page.controller';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public url = APP_CONFIG.url;
  public clientId = 'Power2019App';
  public clientSecret = 'IveGotThePowa!';
  public isProd = false;

  public sessionManagerOpened = false;

  public flushApp = false;

  public navigateOnLogin = '';
  public isRefreshing = false;
  public forgotPasswordOpened = false;

  // public AppControllerInstance: AppController;

  // public isRefreshing = false;

  constructor(private http: HttpClient, private rptSvc: ReportPortalController, private router: Router, private sharedService: SharedService) { }

  public OwinLogin(dto) {
    // when we log in, (Physically press the login button) we need to wipe out any owin information that was previously hanging around,
    // we also need to wipe out any old session data as we are now starting a new session. So clear the local storage.
    this.DumpLastSession();
    const pr = new Promise((resolve, reject) => {
      const path = this.url + 'token';
      let apicall = 'grant_type=password&username=' + dto.username + '&password=' + dto.password;
      apicall = apicall + '&client_id=' + this.clientId;
      apicall = apicall + '&client_secret=' + this.clientSecret;
      // this.sharedService.CauseLoading();
      this.http.post(path, apicall, {headers: {'Content-Type': 'application/x-www-form-urlencoded'}}).toPromise().then(
        res => {
          // this.sharedService.StopLoading();
          this.AuthLoginSuccess(res as AuthorizationData);
          resolve();
        }, rej => {
          // this.sharedService.StopLoading();
          reject(rej);
        });
    });
    sessionStorage.setItem('Auth_Username', dto.username);
    return pr;
  }

  OwinRegister(registration) {
    const pr = new Promise((resolve, reject) => {
    this.http.post(this.url + 'api/account/register', registration).toPromise().then(
            res => {
                console.log(res);
                resolve(res);
            }, rej => {
                console.log(rej);
                reject(rej);
            }
        );
    });
    return pr;
  }

  public IsLoggedIn(){
    const isLoggedIn = sessionStorage.getItem('isLoggedIn');
    if (isLoggedIn && isLoggedIn === 'true') { return true; }
    else { return false; }
  }


  public ForceLogout() {
    console.log('Forcing logout');
    this.Logout();
  }


  public GetPermissions() {
    console.log('getting permissions...');
    const url = this.url + 'api/user/getpermissions';
    const pr = new Promise((resolve, reject) => {
      this.http.get(url).toPromise().then(
        res => {
          resolve(res);
        }, rej => {
          reject(rej);
        });
    });
    return pr;
  }

  public CheckSessions() {
    console.log('checking sessions...');
    const url = this.url + 'api/user/checksessions';
    const pr = new Promise((resolve, reject) => {
      this.http.get(url).toPromise().then(
        res => {
          resolve(res);
        }, rej => {
          reject(rej);
        });
    });
    return pr;
  }

  public GetSessions() {
    console.log('getting sessions...');
    const url = this.url + 'api/user/getsessions';
    const pr = new Promise((resolve, reject) => {
      this.http.get(url).toPromise().then(
        res => {
          resolve(res);
        }, rej => {
          reject(rej);
        });
    });
    return pr;
  }

  public GetRole() {
    console.log('getting user role...');
    const url = this.url + 'api/user/getrole';
    const pr = new Promise((resolve, reject) => {
      this.http.get(url).toPromise().then(
        res => {
          resolve(res);
        }, rej => {
          reject(rej);
        });
    });
    return pr;
  }

  public CallLogout() {
    console.log('logging out...');
    const url = this.url + 'api/user/logout';
    const pr = new Promise((resolve, reject) => {
      this.http.get(url).toPromise().then(
        res => {
          resolve(res);
        }, rej => {
          reject(rej);
        });
    });
    return pr;
  }

  public ForgotPassword(email) {
    console.log('requesting forgot password.');
    const url = this.url + 'api/user/forgotpassword?email=' + encodeURI(email);
    const pr = new Promise((resolve, reject) => {
      this.http.get(url).toPromise().then(
        res => {
          resolve(res);
        }, rej => {
          reject(rej);
        });
    });
    return pr;
  }

  public ResetPassword(resetid, userid, secretid, password) {
    const ResetPasswordDTO = {resetid: resetid, userid: userid, secretid: secretid, password: password, client: this.clientId}
    console.log('requesting reset password.');
    const url = this.url + 'api/user/ResetPassword';
    const pr = new Promise((resolve, reject) => {
      this.http.post(url, ResetPasswordDTO).toPromise().then(
        res => {
          resolve(res);
        }, rej => {
          reject(rej);
        });
    });
    return pr;
  }

  public ClosePreviousSessions() {
    console.log('closing previous sessions...');
    const url = this.url + 'api/user/closeprevioussessions';
    const pr = new Promise((resolve, reject) => {
      this.http.get(url).toPromise().then(
        res => {
          resolve(res);
        }, rej => {
          reject(rej);
        });
    });
    return pr;
  }

  public GetYearListForActiveUser() {
    console.log('getting year list by active user...');
    const url = this.url + 'api/years/GetYearListForActiveUser';
    const pr = new Promise((resolve, reject) => {
      this.http.get(url).toPromise()
        .then(
          res => {
            resolve(res);
          }, rej => {
            reject(rej);
          }
        );
    });
    return pr;
  }

  public ChangeYear(year: Year) {
    console.log('changing year...');
    const yearId = year.numYearId;
    this.sharedService.SetYear(year);
    const url = this.url + 'api/user/ChangeYear?id=' + yearId;
    const pr = new Promise((resolve, reject) => {
      this.http.get(url).toPromise()
        .then(
          res => {
            resolve(res);
          }, rej => {
            reject(rej);
          }
        );
    });
    return pr;
  }

  public ChangePassword(oldPW, newPW) {
    const dto = {'Old': oldPW, 'New': newPW, 'Client': this.clientId};
    console.log('changing password...');
    const url = this.url + 'api/user/changepassword';
    const pr = new Promise((resolve, reject) => {
      this.http.post(url, dto).toPromise().then(
        res => {
          resolve(res);
        }, rej => {
          reject(rej);
        });
    });
    return pr;
  }

  public AdminChangePassword(username, newpw) {
    const dto = {'Username': username, 'New': newpw, 'Client': this.clientId};
    console.log('changing password...');
    const url = this.url + 'api/admin/changeuserpassword';
    const pr = new Promise((resolve, reject) => {
      this.http.post(url, dto).toPromise().then(
        res => {
          resolve(res);
        }, rej => {
          reject(rej);
        });
    });
    return pr;
  }












  /// OTHER TEMPLATED STUFF STARTS HERE:

  public CheckAdmin(){ // checks if admin button should show (Based on isadmin or viewsupport)
    const path = this.url + 'api/ApplicationUsers/CheckAdmin';
    const pr = new Promise((resolve, reject) => {
        this.http.get(path).toPromise().then(
            res => {// success
                const r = res;
                resolve(r);
            },
            rej => {
                console.log(rej);
                reject('failed to check user status');
            }
        );
    });
    return pr;
}

public IsAdmin(){ // is true ONLY when IsAdmin
    const path = this.url + 'api/ApplicationUsers/IsAdmin';
    const pr = new Promise((resolve, reject) => {
        this.http.get(path).toPromise().then(
            res => {// success
                const r = res;
                resolve(r);
            },
            rej => {
                console.log(rej);
                reject('failed to check is user Admin');
            }
        );
    });
    return pr;
}

Register(registration) {
    registration.Client = this.clientId;
    const pr = new Promise((resolve, reject) => {
    this.http.post(this.url + 'api/account/register', registration).toPromise().then(
            res => {
                console.log(res);
                resolve(res);
            }, rej => {
                console.log(rej);
                reject(rej);
            }
        );
    });
    return pr;
}

  // public isRefreshing() {
  //   const x = sessionStorage.getItem('isRefreshing');
  //   if(x){ return x === 'true' ? true : false; }
  //   else{ return false; }
  // }

  // public SetIsRefreshing(b: boolean) {
  //   sessionStorage.setItem('isRefreshing', b.toString());
  // }

  public RefreshToken(){
    const refresh_token = sessionStorage.getItem('Auth_RefreshToken');
    // if(!this.isRefreshing()) {
    if(!this.isRefreshing) {
      if(refresh_token) {
        //this.SetIsRefreshing(true);
        this.isRefreshing = true;
        console.log('Refreshing Token');
        let data = 'grant_type=refresh_token';
        data += '&refresh_token=' + refresh_token;
        data += '&client_id=' + this.clientId;
        data += '&client_secret=' + this.clientSecret;
        this.http.post(this.url + 'token', data,
        {headers: {'Content-Type': 'application/x-www-form-urlencoded'}}).toPromise().then(
            res => {// success
              console.log('%cSuccessfully Refreshed Token', 'color:green; font-weight: bold;');
              const r = res as AuthorizationData;
              this.AuthLoginSuccess(r);
              //this.SetIsRefreshing(false);
              this.isRefreshing = false;
            },
            rej => {
              console.log('%cToken Refresh Failed', 'color:green; font-weight: bold;');
              if(rej && !rej.status){
                  console.log('Unable to communicate with the server, Please try again later.');
              }
              else if (rej && rej.error) {
                  if (rej.error.error_description) {
                      console.log(rej.error.error_description);
                  } else {
                    console.log(rej.message);
                  }
              } else {
                console.log(rej.message);
              }
              this.ForceLogout();
              //this.SetIsRefreshing(false);
              this.isRefreshing = false;
            }
        );
      }
      else if(this.IsLoggedIn()) { // if logged in and we do not have a refresh token, then get booted.
        this.ForceLogout();
      }
    }
  }



  public Logout(){
    this.flushApp = true;
    this.CallLogout();
    const pci = PageController.Instance;
    if(pci) {
      pci.ResetPageControllers();
    }
    this.sessionManagerOpened = false;
    this.DumpLastSession();
    this.router.navigate(['/login']);
  }

  private DumpLastSession(){
    // sessionStorage.clear();
    sessionStorage.removeItem('Auth_Token');
    sessionStorage.removeItem('Auth_Username');
    sessionStorage.removeItem('Auth_RefreshToken');
    sessionStorage.removeItem('Auth_ExpiresAt');
    sessionStorage.removeItem('isLoggedIn');
    sessionStorage.removeItem("activeYearId");
    sessionStorage.removeItem("activeYearDescr");
    sessionStorage.removeItem("activeYearAccess");
    sessionStorage.removeItem("activeYearStart");
    sessionStorage.removeItem("activeYearEnd");
    sessionStorage.removeItem("role");
  }

  private AuthLoginSuccess(authdata: AuthorizationData) {
    // console.log('login success', authdata);
    if (authdata && authdata.access_token) {
      // console.log(authdata);
      sessionStorage.setItem('Auth_Token', authdata.access_token);
      sessionStorage.setItem('Auth_Username', authdata.userName);
      sessionStorage.setItem('Auth_RefreshToken', authdata.refresh_token);
      sessionStorage.setItem('Auth_ExpiresAt', authdata['.expires']);

      //MODIFYING ACCESS EXPIRATION FOR TESTING
      // const dt = new Date(authdata['.expires']);
      // const newDate = new Date(dt.valueOf() - 26 * 60000);
      // console.log('expires is: ' , dt);
      // console.log('newDate is: ' , newDate);
      // sessionStorage.setItem('Auth_ExpiresAt', newDate.toString());


      sessionStorage.setItem('isLoggedIn', 'true');
      // sessionStorage.setItem("activeYearId", "96017");
      return 'Success! Logging In...';
    } else {
      console.log('no response');
      return 'Invalid Username or Password';
    }
  }

  public GetUsername() {
    const x = sessionStorage.getItem('Auth_Username');
    if(x){return x;}
    else{return 'Unknown';}
  }

  public GetCleanUsername(){
    let x = this.GetUsername();
    if(x && x.indexOf('@') >= 0){
        const split = x.split('@');
        x = split[0];
    }
    return x;
  }

  public GetToken() {
    const x = sessionStorage.getItem('Auth_Token');
    if(x){return x;}
    else{return '';}
  }

  public isAuthenticated(): boolean {
    // get the token
    console.log('called is authenticated');
    // const x = new TokensManagerService(this.http);
    // x.getRefreshTokens();

     const token = this.GetToken();
    // return a boolean reflecting
    // whether or not the token is expired
    if(token){
        // const helper = new JwtHelperService();
        // const isExpired = helper.isTokenExpired(token);
        // console.log('isexpired:',isExpired);
        // console.log('token:',token);
        // const decodedToken = helper.decodeToken(token);
        // console.log('decoded:',decodedToken);
        // const expires = helper.getTokenExpirationDate(token);
        // console.log('expires:', expires);
        return true; // decode.tokenNotExpired(null, token);
    }
    else{
        return false;
    }
  }

  public isLoggedIn() {
    const storageLoggedIn = sessionStorage.getItem('isLoggedIn');
    console.log(storageLoggedIn);
    if(storageLoggedIn && storageLoggedIn === 'true') {
      return true;
    }
    else {
      return false;
    }
  }
}



class AuthorizationData {
  userName = '';
  refresh_token = '';
  access_token = '';
  useRefreshTokens: Boolean;
  expires_in: number;
  token_type = '';
  'as:client_id' = '';
  '.issued' = '';
  '.expires' = '';

}
