import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DataService } from '../services/dataservice.service';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { APP_CONFIG } from '../services/ApplicationConfig.service';
import { SharedService } from '../services/shared.service';
import { Year } from '../models/Year';
import { FeatureFlagsService } from '../services/feature-flags.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  app_version = APP_CONFIG.version;
  baseUrl = DataService.baseUrl;
  public loginDTO = {
    username: '',
    password: ''
  };
  busy = false;
  showAppSelector = false;
  RequestedApplication = 'App';
  ShowYearSelection = false;
  Years: any[] = [];
  selectedYearDescr = '';
  selectedYear: Year;
  featureFlagTest= 'untested';
  ShowFeatureFlagTest = false;

  constructor(private http: HttpClient, private ds: DataService, private auth: AuthService, public sharedService: SharedService
                , public router: Router, public featureFlagService: FeatureFlagsService) {
  }

  public login() {
    if(!this.busy) {
      this.busy = true;
      if(this.ShowYearSelection) {
        this.SubmitYearSelection();
      }
      else {
        this.auth.OwinLogin(this.loginDTO).then(res=> {
          this.auth.GetRole().then(res2 => {
            this.sharedService.SetRole(res2 as any);
            // if we are a district rep, we should only have access to the report portal.
            // so we can just naviage there.
            console.log('role is: ', res2);
            if(res2 && res2 == 'DistrictRep') { this.RequestedApplication = 'Reports';}
            else { this.GetRequestedApplication(); }
            if(this.RequestedApplication === 'Reports') {
              this.busy = false;
              this.router.navigate(['reports']);
            }
            else {
              this.GetYearList();
            }
          }, rej=> {
            console.log('Get role failed ' , rej);
          });
        }, rej => {
          console.log('rejected', rej);
          this.busy = false;
          if(rej.status === 400) {
            this.ds.ToastWarning(rej.error.error_description);
          }
          else {
            this.ds.ToastError('Failed to login, The server may be unavailable. Please try again.');
          }
        });
      }
    }
  }

  ngOnInit() {
    this.GetRequestedApplication();
    this.featureFlagService.loadFeatureFlags();
  }


  public GetYearList() {
    this.auth.GetYearListForActiveUser().then(res=> {
      this.Years = res as Year[];
      this.ShowYearSelection = true;
      this.busy = false;
      this.SelectFirstYear();
    }, rej => {
      console.log('failed to get years for user', rej);
      this.busy = false;
    });
  }

  private SelectFirstYear() {
    if(this.Years) {
      if(this.Years.length > 0) {
        const yr: Year = this.Years[0];
        this.selectedYear = yr;
        this.selectedYearDescr = yr.strYearDescription;
      }
    }
  }

  private SubmitYearSelection() {
    if(this.selectedYear) {
      if(this.selectedYear.numYearId) {
        this.auth.ChangeYear(this.selectedYear).then(res => {
          this.auth.CheckSessions().then(res2 => {
            const hasmultiple = res2 as boolean;
            this.busy = false;
            if(hasmultiple) {
              this.auth.sessionManagerOpened = true;
            }
            this.router.navigate(['']);
          }, rej => {
            console.log('failed to check sessions');
            this.ShowYearSelection = false;
            this.busy = false;
          });
        }, rej => {
          console.log('failed to change year');
          this.ShowYearSelection = false;
          this.busy = false;
        });
      }
    }
  }


  cmdAppSelect(){
    this.sharedService.SetRequestedApp();
    this.GetRequestedApplication();
  }

  cmdReportsSelect() {
    this.sharedService.SetRequestedReports();
    this.GetRequestedApplication();
  }

  GetRequestedApplication() {
    this.RequestedApplication = sessionStorage.getItem('RequestedApplication');
    if(this.RequestedApplication === 'Reports') {
      this.showAppSelector = true;
    }
  }

  fShowFeatureFlagTest(){
    return this.ShowFeatureFlagTest 
  }

  cmdVersionInfo(){
    this.ShowFeatureFlagTest = this.featureFlagService.getFeatureFlag("220226-02 ShowFeatureFlagTest");
    const url = DataService.baseUrl + 'assets/changelog/changelog.html';
    const win = window.open(url, '_blank', 'width=550,height=550');
    win.focus();
  }

  cmdFeatureFlagTest(){     
    var fftest = this.featureFlagService.getFeatureFlag("220226-01 Prevent Dupes");
    if (fftest== null){
      this.featureFlagTest = "No Value";
    }
    else{       
      if (fftest){
        this.featureFlagTest = "Value = True" ;
        } 
      else{
        this.featureFlagTest = "Value = False" ;
      }
    }
    
  }

}
