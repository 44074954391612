import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Router } from '@angular/router';
import { APP_CONFIG } from '../services/ApplicationConfig.service';

@Injectable()
export class ReportAuthGuard implements CanActivate {
  constructor(private router: Router) {}

  canActivate() {
    document.title = APP_CONFIG.reportTitle;
    // check url first and set up our intended destination
    if (sessionStorage.getItem('isLoggedIn')) {
        return true;
    }
    else{
      sessionStorage.setItem('RequestedApplication', 'Reports');
        this.router.navigate(['/login']);
        return false;
    }
  }
}
