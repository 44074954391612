import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})

export class RegistrationComponent implements OnInit {
  isLoggedIn: boolean = false;
  status = '';
  message = '';
  messageDetails = '';
  registering = false;
  registered = false;
  registration = {
    userName : '',
    password: '',
    confirmPassword: '',
  };

  constructor(private authservice: AuthService, public router: Router) {
    this.isLoggedIn = authservice.isLoggedIn();
  }

  ngOnInit() {

  }


  cmdRegister(){
    if(!this.registering && !this.registered) {
      if(this.registration && this.registration.userName && this.registration.password && this.registration.confirmPassword){
        this.registering = true;
        this.authservice.Register(this.registration).then(x => {
          console.log('Register Resolved', x);
          this.registerSuccess();
        },
        rej => {
          console.log('Register Rejected', rej);
          this.registerFail(rej);
        });
      }
      else{
        this.status = 'fillout';
      }
    }
  }

  registerSuccess(){
    this.registered = true;
    this.registering = false;
    this.status = 'success';
    setTimeout(() => {
      console.log('Navigating');
      this.router.navigate(['/login']);
    }, 3000);
  }

  registerFail(fail:any){
    this.registering = false;
    this.status = 'fail';
    if(fail && fail.error) {
      this.message = fail.error.message;
      if(fail.error.modelState){
        if(fail.error.modelState["userModel.UserName"] && fail.error.modelState["userModel.UserName"].length) {
          fail.error.modelState["userModel.UserName"].forEach(element => {
            this.messageDetails += (this.messageDetails ? ', ' : '') + element;
          });
        }
        if(fail.error.modelState["userModel.Password"] && fail.error.modelState["userModel.Password"].length) {
          fail.error.modelState["userModel.Password"].forEach(element => {
            this.messageDetails += (this.messageDetails ? ', ' : '') + element;
          });
        }
      }
    }
    else{
      this.resetFailMessage();
    }
  }

  resetFailMessage() {
    this.message = '';
    this.messageDetails = '';
  }


};
