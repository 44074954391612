import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'forgotpassword',
  templateUrl: './forgotpassword.component.html',
  styleUrls: ['./forgotpassword.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  lastemail = '';
  email = '';
  messageclass = 'resetlabelsuccess';
  message = '';
  constructor(public auth: AuthService) { }

  ngOnInit() {

  }

  public SetSuccessMessage() {
    this.messageclass = 'resetlabelsuccess';
    this.message = 'An email has been sent. Please follow the instructions to reset your password.';
  }



  cmdSubmit() {
    if(this.email){
      if(this.email != this.lastemail) {
        this.lastemail = this.email;
        this.auth.ForgotPassword(this.email).then(res=> {
          this.SetSuccessMessage();
        }, rej=> {
          this.messageclass = 'resetlabelerror';
          if(rej && rej.error){
            if(rej.error.Message) {
              this.message = rej.error.Message;
            }
            else if(typeof rej.error == 'string') {
              this.message = rej.error;
            }
            else {
              this.message = 'An error occurred.';
            }
          }
          else {
            this.message = 'An error occurred.';
          }
          console.log(rej);
        });
      }
    }
    else {
      this.messageclass = 'resetlabelerror';
      this.message = 'Enter a valid username or email address';
    }
  }

  cmdCancel() {
    this.email = '';
    this.lastemail = '';
    this.auth.forgotPasswordOpened = false;
  }



}
