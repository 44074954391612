import { Building } from './Building';
import { Provider } from './Provider';
import { Status } from './Status';
import { ServiceClass } from './ServiceClass';
import { LoadArea } from './LoadArea';
import { AcctSupplier, tblAcctSupplier } from './AcctSupplier';

export class AccountInfo {

  public strAccountId = '';
  public ysnMasterAccount: boolean;
  public strMeterNum: number;
  public strAccountType = '';
  public numBuildingId: number;
  public numSupplierId: number;
  public numUtilityId: number;
  public numStatusId: number;
  public numLYFA: number;
  public ysnAbnormalExclusion: boolean;
  public numServiceClassId: number;
  public numLoadAreaId: number;
  public building: Building = new Building();
  public supplier: Provider = new Provider();
  public utility: Provider = new Provider();
  public status: Status = new Status();
  public serviceClass: ServiceClass = new ServiceClass();
  public loadArea: LoadArea = new LoadArea();
  public isNew = false;
}

export class AccountInfoASL {

  public AccountInfo: AccountInfo;
  public AcctSupplierList: AcctSupplier[];
}
export class AccountInfo_tblASL {

  public AccountInfo: AccountInfo;
  public AcctSupplierList: tblAcctSupplier[];
}

