import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Router } from '@angular/router';
import { APP_CONFIG } from '../services/ApplicationConfig.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private router: Router) {}

  canActivate() {
    document.title = APP_CONFIG.appTitle;
    // check url first and set up our intended destination
    if (sessionStorage.getItem('isLoggedIn') && sessionStorage.getItem('activeYearId')) {
      //If we are trying to goto the app we require the yearid and isLoggedIn, otherwise we were at the portal previously
      // or never completely logged in to begin with.
        return true;
    }
    else{
      sessionStorage.setItem('RequestedApplication', 'App');
        this.router.navigate(['/login']);
        return false;
    }
  }
}
