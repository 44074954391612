import { Injectable } from "@angular/core";
import { Router } from '../../../../node_modules/@angular/router';
import { DataService } from '../../services/dataservice.service';
import { AuthService } from '../../services/auth.service';
import { APP_CONFIG } from '../../services/ApplicationConfig.service';
import { PageController } from './page.controller';
import { Subject } from '../../../../node_modules/rxjs';
import { UserSettings } from '../../models/UserSession';
import { SharedService } from '../../services/shared.service';
// import { DataService } from '../services/dataservice.service';

@Injectable()//{ providedIn: "root" })
export class AppController {

  public static Instance: AppController;

  public activePageName = '';

  public sessionTimeout = 1800; // seconds. (3600 = 1 hours) - Or... (1800 = half hour).
  public maxSessionTimeout = 1800; // Number we reset sessionTimeout too when active.
  public showInactiveSessionAt = APP_CONFIG.showInactiveSessionAt;// (600) when we reach this number, we will display inactive session dialog.
  public showingInactiveSession = false; // showing the dialog that pops up when inactive. (session timeout)

  public passwordchangeforadmin = false; // when the panel is opened up for another user by an admin on admin panel
  public passwordchangeusername = '';
  public showingChangePassword = false;
  public forcePasswordChange = false;

  public showingChangeYear = false;

  public toPost = '';

  public passwordExpireMessage = '';

  constructor(private router: Router, private ds: DataService, private auth: AuthService, 
              private pc: PageController, private shared: SharedService) {
    console.log('app controller constructor');
    AppController.Instance = this;
  }

  public GetMaxSessionTimeout() {
    this.ds.GetUserSettings().then((res: UserSettings)=> {
      this.maxSessionTimeout = res.MaxSecondsInactive;
      this.sessionTimeout = this.maxSessionTimeout;
    }, rej => {
      console.log('failed to get max session timeout. Using 1800.', rej);
    });
  }

  public ShowChangePassword(force:boolean) {
    this.passwordchangeforadmin = false;
    this.passwordchangeusername = '';
    this.forcePasswordChange = force;
    this.showingChangePassword = true;
  }

  public ShowAdminChangePassword(username: string) {
    this.passwordchangeforadmin = true;
    this.passwordchangeusername = username;
    this.forcePasswordChange = false;
    this.showingChangePassword = true;
  }

  public showInactiveSession(){
    this.showingInactiveSession = true;
  }

  public SessionTimeoutTick() {
    if(this.sessionTimeout > 0) {
      this.sessionTimeout -= 1;
      if(this.sessionTimeout <= this.showInactiveSessionAt) {
        this.showingInactiveSession = true;
      }
      if(this.sessionTimeout <= 0) {
        this.EndSession();
      }
      // console.log(this.sessionTimeout);
    }
  }

  public UpdateTimeout() {
    // console.log(this.sessionTimeout , this.maxSessionTimeout);
    this.sessionTimeout = this.maxSessionTimeout;
    this.showingInactiveSession = false;
    // send a service call to update session last active.
  }

  public EndSession() {
    this.showingInactiveSession = false;
    this.ClearPage(false);
    this.auth.ForceLogout();
  }

  public UpdatePasswordExpire() {
    this.ds.CheckPasswordExpire().then(res=>{
      let expired = false;
      const passwordExpire: Date = res ? new Date(res as string) : new Date();
      const curDate = new Date();
      if(passwordExpire) {
        if(curDate >= passwordExpire) { expired = true; }
      }
      else { expired = true; }
      if(expired) {
        this.passwordExpireMessage = 'Your password has expired.';
        this.ShowChangePassword(true);
      }
      else {
        const oneday = 24 * 60 * 60 * 1000;
        const days = Math.round(Math.abs((passwordExpire.getTime() - curDate.getTime()) / (oneday))); //= passwordExpire - curDate;
        if(days === 0 ) {
          this.passwordExpireMessage = 'Your password will expire today.';
        }
        else {
          const plural = (days > 1);
          this.passwordExpireMessage = 'Your password will expire in ' + days.toString() + ' day' + (plural ? 's' : '');
        }
      }
    }, rej =>{
      console.log('get password expire failed', rej);
    });
  }

  Initialize() {

  }



  Close() { // closes whichever page the user is on.
    this.router.navigate(['']);
    this.ClearPage(true);
  }

  public ClearPage(updateMotD) {
    this.activePageName = '';
    this.shared.appstatus = '';
    if(updateMotD) {
      this.pc.newEvent('UpdateMotD');
    }
  }

  public RefreshAppData() {
    // basically, when we change the year, we need to flush any data that was loaded up from a previous year.
    // This means clean out all page service controllers and refrsh data within local session and applayout
    this.pc.ResetPageControllers();
    this.toPost = '';
    this.GetUnpostedCount();
    this.router.navigate(['']);
  }


  public GetUnpostedCount() {
    this.ds.GetUnpostedCount().then(res => {
      this.toPost = res as any;
    }, rej => {
      console.log('failed to get unposted count', rej);
    });
  }

}
