import { District } from './District';
import { Status } from './Status';
import { LoadArea } from './LoadArea';
import { ServiceClass } from './ServiceClass';

export class Building {
  public numBuildingId: number;
  public numDistrictId: number;
  public strBuildingName = '';
  public strStreet = '';
  public strCity = '';
  public strState = '';
  public strZip = '';
  public strPhone = '';
  public numStatusId: number;
  public numLYFA: number;
  public numServiceClassId: number;
  public numLoadAreaId: number;
  public district: District = new District();
  public status: Status = new Status();
  public loadArea: LoadArea = new LoadArea();
  public serviceClass: ServiceClass = new ServiceClass();
  public accountDependants = 0;
  public transactionDependants = 0;
  public installmentDependants = 0;
  public isParentActive = false;

}

export class BuildingListItem {
  public numBuildingId: number;
  public strBuildingName = '';
}
