import { Component, OnInit, Pipe, PipeTransform } from '@angular/core';
import { MessageService } from 'primeng/components/common/messageservice';
import { DataService } from './services/dataservice.service';
import { AuthService } from './services/auth.service';
import { PingService } from './auth/ping.service';
import { SharedService } from './services/shared.service';
import { DatePipe } from '../../node_modules/@angular/common';
import { FeatureFlagsService } from './services/feature-flags.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [MessageService]
})
export class AppComponent implements OnInit {
  title = 'PowerPortal2019';
  feature: boolean;

  constructor(public messageService: MessageService, private dataservice: DataService, public auth: AuthService, public pingService: PingService, 
                    public sharedService: SharedService, public featureFlagService: FeatureFlagsService) {
    this.dataservice.toastOneTimeSuccess.subscribe(value => this.ToastOneTimeSuccess(value));
    this.dataservice.toastSuccess.subscribe(value => this.ToastSuccess(value));
    this.dataservice.toastError.subscribe(value => this.ToastError(value));
    this.dataservice.toastInfo.subscribe(value => this.ToastInfo(value));
    this.dataservice.toastWarning.subscribe(value => this.ToastWarning(value));
    this.dataservice.toastOneTimeWarning.subscribe(value => this.ToastOneTimeWarning(value));
    this.dataservice.toastClear.subscribe(() => this.ToastClear());
    
  }

  public ToastOneTimeSuccess(msg){
    this.messageService.clear();
    this.messageService.add({severity:'success', summary:'Success', detail: msg});
  }

  public ToastSuccess(msg){
    this.messageService.add({severity:'success', summary:'Success', detail: msg});
  }

  public ToastError(msg){
      this.messageService.add({severity:'error', summary:'Error', detail: msg});
  }

  public ToastInfo(msg){
      this.messageService.add({severity:'info', summary:'', detail: msg});
  }

  public ToastWarning(msg){
      this.messageService.add({severity:'warn', summary:'Warning', detail: msg});
  }

  public ToastOneTimeWarning(msg){
    this.messageService.clear();
    this.messageService.add({severity:'warn', summary:'Warning', detail: msg});
 }

  public ToastClear(){
      this.messageService.clear();
  }

  ngOnInit() {
    this.sharedService.ApplyRole();
  }



}



