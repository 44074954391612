import { Injectable } from "@angular/core";
import { DataService } from '../../services/dataservice.service';
import { FormatDatePipe } from 'src/app/shared/pipes/ApplicationPipes';
import { MonthToLabel } from 'src/app/shared/utilities';

@Injectable()//{ providedIn: "root" })
export class ReportPortalController {

  public MessageOfDay = '';
  public selectedDistrict = '';
  public selectedContractYear = '';
  public selectedFiscalYear = '';
  public selectedCalendarYear = '';
  public selectedCalendarYearNum = 0;
  public selectedCustomDateStart: Date;
  public selectedCustomDateEnd: Date;
  public selectedCustomMonthStart = '';
  public selectedCustomMonthEnd = '';
  public selectedYearType = 'Contract Year';
  public selectedFilterSet = '';
  public DefaultDistrictName;
  public district: any;
  public yearId = -1;
  public reportType = '';
  public report = 'Natural Gas';
  public grouping = 'overall';
  public columns: any[] = [];
  public aggregation = 'Total';
  public filterSetId = '';
  public showGraph = true;
  public customProps = true;
  public showCustomProps = false;

  // User input encapsulation
  // public reportParams = {
  //   district: {
  //     strDistrictName: '',
  //     numDistrictId: -1
  //   },
  //   yearType: 'Fiscal Year',
  //   yearId: -1,
  //   dtmCustomStart: null,
  //   dtmCustomEnd: null,
  //   reportType: '',
  //   report: 'Natural Gas',
  //   grouping: 'overall',
  //   columns: [],
  //   aggregation: '',
  //   filterSetId: ''
  // };

  public years = [];
  public calendaryears = [];
  public columnyears = [];

  public districts: any[] = [];

  public filterSets = [];
  public gettingYears = false;
  public gettingDistricts = false;
  private tokenWhenGotDistricts = '';

  public fiscalMonth = 12;
  public fiscalLabel = 'December';
  public allFiscalLabels = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    
  public foundfiscalpref = false;

  public allYearTypes = ['Contract Year', 'Fiscal Year','Calendar Year','Custom Date'];
  public usageyeartypes = ['Calendar Year', 'Custom Date'];

  public yearTypes = this.allYearTypes;

  constructor(private ds: DataService) {}


  Initialize() {
    this.GetMessageOfDay();
    this.GetDistricts();
    this.GetFiscalPref();
  }

  public GetFiscalPref() {
    this.ds.GetFiscalPref().then(res=> {
      let x = res as number;
      if(x) {
        this.foundfiscalpref = true;
      }
      else{
        x = 12;
        this.foundfiscalpref = false;
      }
      this.fiscalMonth = x;
      this.fiscalLabel = MonthToLabel(x);
    }, rej=> {
      console.log('failed to get fiscal month');
    });
  }

  public GetDistricts() {
    this.gettingDistricts = true;
    const prevSelected = this.selectedDistrict;
    // console.log('Previously Selected: ', prevSelected);
    this.ds.GetDistrictListForUser().then(res=>{
      const val: any = res;
      this.districts = val;
      if(!this.districts) { this.districts = []; }
      let toSelect = null;
      if(this.districts && this.districts.length > 0) {
        let found = false;
        if(prevSelected) {
          this.districts.forEach(e => {
            if(e.strDistrictName == prevSelected) {
              found = true;
            }
          });
        }
        if(!found) { toSelect = this.districts[0]; }
      }
      if(toSelect) {
        this.SelectDistrict(toSelect);
      }
      this.gettingDistricts = false;
    } , rej => {
       console.log('failed to get districts');
       this.gettingDistricts = false;
     });
  }


  public CheckCustomPropertyByDistrict() {
    if(!this.district) {
      this.showCustomProps = false;
    }
    else {
      this.ds.CheckCustomPropertyByDistrict(this.district.numDistrictId).then(res=> {
        this.showCustomProps = res as boolean;
      }, rej => {
        console.log(rej);
      });
    }
  }


  getYearListByDistrictId()
  {
    this.gettingYears = true;
    this.years = [];
    this.calendaryears = [];
    this.columnyears = [];
    //this.selectedCustomDateStart = null;
    if(this.district) {
      console.log(`getting years for : ${this.district.numDistrictId}`);
      this.ds.GetYearListForDistrictId(this.district.numDistrictId).then(data => {
        const val: any = data;
        this.years = val;
        // console.log(this.years);
        this.GetCalendarYearsFromYears(val);
        this.GetColumnYearsFromYears(val);

        let selectContract = null;
        let selectFiscal = null;
        if(this.years && this.years.length > 0) {
          selectContract = this.years[0];
          selectFiscal = this.years[0];
        }
        this.SelectContractYear(selectContract);
        this.SelectFiscalYear(selectFiscal);

        let selectCalendar = null;
        if(this.calendaryears && this.calendaryears.length > 0) { selectCalendar = this.calendaryears[0]; }
        this.SelectCalendarYear(selectCalendar);

        this.gettingYears = false;
      } , rej => {
        console.log('failed to get years');
        this.gettingYears = false;
      });
    }
  }

  private GetCalendarYearsFromYears(yrs) {
    yrs.forEach(element => {
      //console.log(element);
      const x = element.strYear;
      const num = Number(x);
      if(x) {
        // const descr = num.toString();
        const dtmStart = new Date(num, 0,1,0,0,0,0);
        const dtmEnd = new Date(num+1, 0,1,0,0,-1,0);
        //const descr = this.CalendarYearToDescr(dtmStart, dtmEnd);
        const descr =  dtmStart.getFullYear.toString();
        const obj: any = {strYear: x, strYearDescription: descr, dtmStart: dtmStart, dtmEnd: dtmEnd };
        // console.log("---Year---: " , num);
        // console.log("Start: " , dtmStart);
        // console.log("End: " , dtmEnd);
        this.calendaryears.push(obj);
      }
    });
  }

  private GetColumnYearsFromYears(yrs) {
    // colums years are just years with the none added.
    this.columnyears=[];
    const NoneColumn = {
      Access: null,
      dtmEnd: null,
      dtmStart: null,
      numYear: null,
      numYearId: null,
      strYear: 'None',
      strYearDescription: 'None',
      ysnCurrent: null
    };
    this.columnyears.push(NoneColumn);
    this.years.forEach(element => {
      this.columnyears.push(element);
    });
  }

  private CalendarYearToDescrFromNumber(num) {
    const dtmStart = new Date(num, 0,1,0,0,0,0);
    const dtmEnd = new Date(num+1, 0,1,0,0,-1,0);
    const descr =  dtmStart.getFullYear.toString();
    return descr;
  }

  private CalendarYearToDescr(dtstart, dtend) {     
    const xx1 =  dtstart.toString('MM/dd/yyyy');
    const xx2 =  dtend.toString('MM/dd/yyyy');
    const descr = xx1.toString() + ' - ' + xx2.toString();
    return descr;
  }


  SelectDistrict(event) {
    this.district = event;
    if(event) {
      this.DefaultDistrictName = event.strDistrictName;
      this.selectedDistrict = event.strDistrictName;
      this.CheckCustomPropertyByDistrict();
    }
    else {
      this.selectedDistrict = '';
    }
    this.getYearListByDistrictId();
    this.GetFilterSets();
  }

  GetFilterSets() {
    this.filterSets = [];
    this.selectedFilterSet = '';
    this.filterSetId = '';
    if(this.district) {
      // console.log(`getting filtersets for : ${districtId}`)
      this.ds.GetFilterSetsByDistrictId(this.district.numDistrictId).then(data => {
        if(data) {
          this.filterSets = data as any[];
          //console.log(this.filterSets);
          const empty = {numDistrictId: null, strFilterSetDescription: '', strFilterSetName: '', strUserName: '', uidFilterSetId: ''};
          this.filterSets.unshift(empty);
        }
        else {
          this.filterSets = [];
        }
      } , rej => {
        this.ds.ToastError('Failed to get Filter Sets.');
      });
    }
  }

  SelectContractYear(event) {
    this.yearId = event ? event.numYearId : -1;
    this.selectedContractYear = event ? event.strYearDescription : '';
    if(event) { this.UpdateColumns(event.strYear); }
  }

  SelectFiscalYear(event) {
    this.selectedFiscalYear = event.strYear;
    if(event) { this.UpdateColumns(event.strYear); }
    // this.yearId = event ? event.numYearId : -1;
    // this.selectedFiscalYear = event ? event.strYearDescription : '';
    // if(event) { this.UpdateColumns(event.strYear); }
  }

  SelectCalendarYear(event) {
    this.selectedCalendarYearNum = Number(event.strYear);
    this.selectedCalendarYear = event ? this.CalendarYearToDescrFromNumber(Number(event.strYear)) : '';
    if(event) { this.UpdateColumns(event.strYear); }
  }

  private UpdateColumns(yr) {
    const yrAsNumber = Number(yr) - 4;
    for (let i = 0; i < 5; i++) {
      const x =  (yrAsNumber + i).toString();
      this.columns[i] = x;
    }
  }


  GetMessageOfDay() {
    this.ds.GetPortalMessageOfTheDay().then(res=> {
      this.MessageOfDay = res as any;
    }, rej => {
      // this.ds.ToastError('The Message of the Day failed to load.');
      this.MessageOfDay = 'Failed to retrieve the Message Of The Day...';
      // console.log('failed to get message of the day...');
    });
  }





  Reset() {
    this.MessageOfDay = '';
    this.selectedDistrict = '';
    this.selectedContractYear = '';
    this.selectedFiscalYear = '';
    this.selectedCalendarYear = '';
    this.selectedCalendarYearNum = 0;
    this.selectedCustomDateStart = null;
    this.selectedCustomDateEnd = null;
    this.selectedFilterSet = '';
    // this.reportParams = {
    //   district: {
    //     strDistrictName: '',
    //     numDistrictId: -1
    //   },
    //   contractYear: {
    //     numYearId: -1,
    //     numYear: -1,
    //     strYear: '',
    //     strYearDescription: '',
    //   },
    //   reportType: '',
    //   report: 'Natural Gas',
    //   grouping: 'overall',
    //   columns: [],
    //   aggregation: '',
    //   filterSetId: ''
    // };

    this.years = [];
    this.calendaryears = [];
    this.districts =  [];
  }



}
