import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { DataService } from '../services/dataservice.service';

@Component({
  selector: 'app-resetpassword',
  templateUrl: './resetpassword.component.html',
  styleUrls: ['./resetpassword.component.css']
})
export class ResetPasswordComponent implements OnInit {

  baseUrl = DataService.baseUrl;

  resetid = '';
  userid = '';
  secretid = '';

  newpassword = '';
  confirmpassword = '';

  changesuccess = false;

  errormessage = '';


  constructor(private route: ActivatedRoute, private router: Router, private auth: AuthService, private ds: DataService) {
  }

  ngOnInit() {
    this.route.paramMap.subscribe(
      params => {
        this.resetid = params.get('resetid');
        this.userid = params.get('userid');
        this.secretid = params.get('secretid');
      }
    );
  }

  cmdResetPassword() {
    if(this.newpassword && this.confirmpassword) {
      this.errormessage = '';
      if(this.newpassword == this.confirmpassword) {
        if(this.ValidatePassword()) {
          this.auth.ResetPassword(this.resetid, this.userid, this.secretid, this.newpassword).then(res=> {
            this.ResetSuccess();
          }, rej=> {
            if(rej && rej.error){
              if(rej.error.Message) {
                this.errormessage = rej.error.Message;
              }
              else if(typeof rej.error == 'string') {
                this.errormessage = rej.error;
              }
              else {
                this.errormessage = 'An error occurred.';
              }
            }
            else {
              this.errormessage = 'An error occurred.';
            }
            console.log(rej);
          });
        }
      }
      else {
        this.ds.ToastError('New Password does not match Confirm Password, please fix and try again.');
      }
    }
    else {
      this.ds.ToastError('New Password and Confirm Password are both required.');
    }
  }

  ValidatePassword() {
    return true;
  }



  ResetSuccess() {
    this.changesuccess = true;
    setTimeout(() => {
      this.router.navigate(['login']);
    }, 5000);
  }



}
