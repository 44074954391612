import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
// import { ReportsComponent } from './reports/reports';
import { AuthGuard } from './guard';
import { LayoutAppComponent } from './layout_app/layout_app.component';
import { RegistrationComponent } from './registration/registration.component';
import { LayoutPortalComponent } from './layout_portal/layout_portal.component';
import { ReportAuthGuard } from './guard/reportauth.guard';
import { ResetPasswordComponent } from './resetpassword/resetpassword.component';

const routes: Routes = [
  { path: '', loadChildren: './layout_app/layout_app.module#LayoutAppModule', canActivate: [AuthGuard] },
  { path: 'reports', loadChildren: './layout_portal/layout_portal.module#LayoutPortalModule', canActivate: [ReportAuthGuard] },
  { path: 'login',    component: LoginComponent} ,
  { path: 'register', component: RegistrationComponent },
  { path: 'resetpassword/:resetid/:userid/:secretid', component: ResetPasswordComponent} ,

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
