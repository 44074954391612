import { AccountInfo } from '../models/AccountInfo';
import { Provider } from '../models/Provider';
import { Building } from '../models/Building';
import { Status } from '../models/Status';
import { ServiceClass } from '../models/ServiceClass';
import { LoadArea } from '../models/LoadArea';
import { District } from '../models/District';

export enum Key {
  Backspace = 8,
  Tab = 9,
  Enter = 13,
  Shift = 16,
  Control = 17,
  Pause = 19,
  CapsLock = 20,
  Escape = 27,
  PageUp = 33,
  PageDown = 34,
  Home = 35,
  End = 36,
  ArrowLeft = 37,
  ArrowRight = 39,
  ArrowUp = 38,
  ArrowDown = 40,
  Insert = 45,
  Delete = 46,
  MacCommandLeft = 91,
  MacCommandRight = 93,
  NumLock = 144,
  ScrollLock = 145,
  MacCommandFirefox = 224,
  F1 = 112,
  F2 = 113,
  F3 = 114,
  F4 = 115,
  F5 = 116,
  F6 = 117,
  F7 = 118,
  F8 = 119,
  F9 = 120,
  F10 = 121,
  F11 = 122,
  F12 = 123,
}

export function validateNonCharKeyCode(keyCode: number, ctrlKeyPressed: boolean) {
  // these are keys that do not cause an input to get edited.
  if(ctrlKeyPressed){ return false; }
  else{
    return [
      Key.Enter,
      Key.Tab,
      Key.Shift,
      Key.Control,
      Key.Pause,
      Key.CapsLock,
      Key.Escape,
      Key.PageUp,
      Key.PageDown,
      Key.Home,
      Key.End,
      Key.ArrowLeft,
      Key.ArrowUp,
      Key.ArrowRight,
      Key.ArrowDown,
      Key.Insert,
      Key.MacCommandLeft,
      Key.MacCommandRight,
      Key.NumLock,
      Key.ScrollLock,
      Key.MacCommandFirefox,
      Key.F1, Key.F2, Key.F3,
      Key.F4, Key.F5, Key.F6,
      Key.F7, Key.F8, Key.F9,
      Key.F10, Key.F11, Key.F12
    ].every(codeKey => codeKey !== keyCode);
  }
}

export function onNumericKeyDown(event): void {
  if(event){
    if (!(event.ctrlKey && (event.key === 'c' || event.key === 'a')) &&
        !(isKeyPressedNumeric(event) || event.keyCode === Key.Enter || event.keyCode === Key.Delete || event.keyCode === Key.Backspace || event.keyCode == Key.Tab)) {
        if (event.preventDefault){
            event.preventDefault();
        }
    }
  }
}

export function isKeyPressedNumeric(event): boolean {
  const charCode = getCharCodeFromEvent(event);
  const charStr = event.key ? event.key : String.fromCharCode(charCode);
  //return this.isCharNumeric(charStr);
  return !!/\d/.test(charStr);
}

export function getCharCodeFromEvent(event): any {
  event = event || window.event;
  return (typeof event.which === 'undefined') ? event.keyCode : event.which;
}


export function PrepareAccount(account: AccountInfo) {
  if(account.supplier == null) { account.supplier = new Provider(); }
  if(account.utility == null) { account.utility = new Provider(); }
  if(account.building == null) { account.building = new Building(); }
  if(account.status == null) { account.status = new Status(); }
  if(account.serviceClass == null) { account.serviceClass = new ServiceClass(); }
  if(account.loadArea == null) { account.loadArea = new LoadArea(); }
  account.building = PrepareBuilding(account.building);
  return account;
}


export function PrepareBuilding(building: Building) {
  if(building.district == null) { building.district = new District(); }
  if(building.loadArea == null) { building.loadArea = new LoadArea(); }
  if(building.serviceClass == null) { building.serviceClass = new ServiceClass(); }
  if(building.status == null) { building.status = new Status(); }
  return building;
}


export function VCurrencyConverter(event: any){
  if(event != null) {
    // while inside the text box, this may already be converted to a currency.
    // So we have to first strip out the special characters to allow conversion to a number.
    const e = event.toString().replace('$','').replace(',','');
    let asNumber = Number(e);
    const isString = isNaN(asNumber);
    if(isString) {
      asNumber = 0;
    }
    return asNumber;
  }
  else{
    return null;
  }
}

export function VDateConverter(event: any){
  if(event != null) {
    let dt = new Date(event);
    if(!dt) { dt = new Date();}
    return dt;
  }
  else{
    return null;
  }
}



export function ToDateString(dt:Date) {
  if(!dt) { return null; }
  const month = dt.getMonth() + 1;
  const day = dt.getDate();
  const year = dt.getFullYear();
  const time = '12:00:00 AM';
  const datestring = month + '/' + day + '/' + year +' ' + time;
  return datestring;
}


export function MonthToLabel(month) {
  switch (month) {
    case 1:
      return "January";
    case 2:
      return "February";
    case 3:
      return "March";
    case 4:
      return "April";
    case 5:
      return "May";
    case 6:
      return "June";
    case 7:
      return "July";
    case 8:
      return "August";
    case 9:
      return "September";
    case 10:
      return "October";
    case 11:
      return "November";
    case 12:
      return "December";
  }
  return "";
}

export function LabelToMonth(month) {
  switch (month) {
    case "January":
      return 1;
    case "February":
      return 2;
    case "March":
      return 3;
    case "April":
      return 4;
    case "May":
      return 5;
    case "June":
      return 6;
    case "July":
      return 7;
    case "August":
      return 8;
    case "September":
      return 9;
    case "October":
      return 10;
    case "November":
      return 11;
    case "December":
      return 12;
  }
  return 0;
}





export function GetDateFromFiscalYearMonthEnd(year, month, isEnd = false) {
  // the incoming date will always be the end portion of a fiscal.
  const dt = new Date(year, month, 1, 0, 0, 0, 0);
  if(!isEnd) { // is the start of the YearMonth cycle.
    const yr = dt.getFullYear();
    dt.setFullYear(yr - 1);
    dt.setMonth(month + 1);
  }
  if(isEnd) {
    dt.setMonth(month + 1);
    dt.setSeconds(-1);
  }
  console.log(dt);
  return dt;
}


export function GetCalendarYearStart(year) {
  const dt = new Date(year, 0, 1, 0, 0, 0, 0);
  return dt;
}

export function GetCalendarYearEnd(year) {
  const dt = new Date(year + 1, 0, 1, 0, 0, 0, 0);
  dt.setSeconds(-1);
  return dt;
}

